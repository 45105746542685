export default function ListPrivacyPolicy({data}) {
  const listItemStyle = {
    paddingTop: "4px",
    color: '#506CFF', // Color for the text
  };

  const bulletStyle = {
    content: "●",
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    backgroundColor: '#ffffff', // White color for the bullet
  };

  return data.map((d, index) => {
    return (
      <div key={index} className="mx-2 my-2 me-auto text-white">
        <div className="fw-bold subheadingStyle" >
          {index+1}. {d.title}:
        </div>
        <div>
          {d.descriptions.map((desc, index) => {
           return (
            <div key={index} className='d-flex mt-2 ml-4'>
              <div className='mt-2 mx-3' style={bulletStyle} />
              <div className='w-100 h-auto text-justify'>
                {desc}
              </div>
            </div>
           )
          })}
        </div>
      </div>
    )
  })
}