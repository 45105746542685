import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import '../profile.css';
import avatarImg from '../images/profile.png';
import imgMoney from '../images/money.png';
import { NftAddress } from '../components/NftAddress';

export default function ViewProfileWeb() {

    const { t } = useTranslation();
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?

                        <>


                            <div className="divImgAvatar">
                                <img className="img-fluid" src={avatarImg} alt="User Avatar" width={200} />
                            </div>
                            <div className="divInfoAvatar">
                                <div>
                                    <div className="profileAvatarName">
                                        {account.username}

                                        <a title="Edit Profile.." href="edit-profile-web" className="btn btn-primary btn-sm ml-2">
                                            <i className="fa fa-edit"></i>
                                        </a>
                                    </div>

                                    <div className="clearfix"></div>
                                </div>

                                <div>
                                    <img src={imgMoney} alt="Money" /> {account.totalPoin} Poin
                                    
                                </div>

                                <div className="badgesTitle">
                                    Nama Lengkap
                                </div>
                                <div>
                                    {account.name}
                                </div>

                                <div className="badgesTitle">
                                    Email
                                </div>
                                <div>
                                    {account.email}
                                </div>

                                <div className="badgesTitle">
                                    Wallet Address
                                    <a title="Edit Wallet Address.." href="edit-wallet-web" className="btn btn-primary btn-sm ml-2">
                                        <i className="fa fa-edit"></i>
                                    </a>
                                </div>
                                <div>
                                    <NftAddress
                                        text={account.solAddress}
                                        itemId={account.id}
                                        tagName="A"
                                    ></NftAddress>
                                </div>



                            </div>

                            <div className="clearfix"></div>

                        </>

                        : <NoAccount></NoAccount>
                }

            </div>
        </>
    );
}