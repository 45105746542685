
import JoinUsBG from '../images/join-us-bg.png'
import imgDiscord from '../images/ic-discord.svg'
import imgTwitter from '../images/fa-twitter.svg'
import { useEffect } from 'react';
import ListPrivacyPolicy from '../components/list-privacy-policy';

const listPrivacyPolicy = [
  {
    title: "Information Collection and Use",
    descriptions: [
      <p>Personal Information: The App does not collect personally identifiable information from users.</p>,
      <p>Non-Personal Information: The App may collect non-personal information, such as device information, usage data, and technical information about your device and its interaction with the App.</p>
    ]
  },
  {
    title: "Use of Information",
    descriptions: [
      <p>The non-personal information collected may be used to analyze trends, administer the App, improve user experience, and gather demographic information about our user base.</p>,
    ]
  },
  {
    title: "Third-Party Services",
    descriptions: [
      <p>The App may use third-party services, such as analytics services, advertising networks, and social media platforms. These services may collect information about your use of the App and may set cookies or other tracking technologies.</p>,
    ]
  },
  {
    title: "Advertising",
    descriptions: [
      <p>The App may display advertisements from third-party ad networks. These ad networks may collect and use information about your visits to this and other websites and apps in order to provide targeted advertisements.</p>,
    ]
  },
  {
    title: "Data Security",
    descriptions: [
      <p>The developers of the App take reasonable measures to protect the security of your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>,
    ]
  },
  {
    title: "Children's Privacy",
    descriptions: [
      <p>The App is not directed to children under the age of 13, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</p>,
    ]
  },
  {
    title: "Changes to this Privacy Policy",
    descriptions: [
      <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting within the App or on the Google Play Store listing.</p>
    ]
  },
  {
    title: "Contact Us",
    descriptions: [
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="https://twitter.com/wh3el_io" target='_blank'>X</a> or <a href="https://discord.com/invite/DxhjMQZJ5n" target="_blank">Discord</a>.</p>
    ]
  },
]

export default function PrivacyPolicy() {

  useEffect(() => {
    document.title = 'Privacy Policy - Wh3el City App';
  }, []);

  return (
      <> 
        <div className="container">
          <div className="">
              <p className="privacyPolicyHeading glow-text text-white" >
                  <a className=''>Privacy Policy For Wh3el City</a>
                  {" "} Project On Google Play
              </p>
          </div>

          <div className='mx-5'>
            <div className="my-5 border p-3 text-justify">
              <p className='m-0'>
                This Privacy Policy outlines how information is collected, used, 
                and disclosed by the Wh3el City App. By downloading, installing, 
                or using the App, you consent to the collection and use of your 
                information as described in this Privacy Policy.
              </p>
            </div>

            <div>
              <ListPrivacyPolicy data={listPrivacyPolicy} />
            </div>

            <div className="my-5 border p-3 text-justify">
              <p className='m-0'>
                By downloading, installing, or using Wh3el City on Google Play, 
                you acknowledge that you have read, understood, and agreed to the 
                terms of this Privacy Policy. If you do not agree with any part of 
                this Privacy Policy, you should refrain from downloading or using the App.
              </p>
            </div>
          </div>


          <div className='container-fluid'>
            <div className='join-us-sect mx-auto' style={{backgroundImage: `url('${JoinUsBG}')`}}>
              <div className='contactInformationText'>Contact Information</div>
              <div className='join-us-text-2'> If you have any questions about this Privacy Policy, please contact us at</div>
              <div className='d-flex medsos-sect'>
                  <a href="https://discord.com/invite/DxhjMQZJ5n" className='geeky-medsos'>
                      <img
                          className='pointer mx-auto'
                          src={imgDiscord}
                          width={20}
                          height={20}
                          alt='ic-discord'
                          style={{filter: 'invert(1)'}}
                          // onClick={handleIncrement}
                      />
                  </a>
                  <a href="https://twitter.com/wh3el_io" className='geeky-medsos'>
                      <img
                          className='pointer mx-auto'
                          src={imgTwitter}
                          width={20}
                          height={20}
                          alt='ic-twitter'
                          style={{filter: 'invert(1)'}}
                          // onClick={handleIncrement}
                      />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}