import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/voucher`;

export const voucherService = {
    getOne,
    list,
    mine, 
    exchange, 
    claim, 
    usedVoucher
};

async function getOne(accountVoucherId) {
    return fetchWrapper.get(`${baseUrl}/getone?accountVoucherId=${accountVoucherId}`);
}

async function list(page, size) {
    return fetchWrapper.get(`${baseUrl}/list?page=${page}&size=${size}`);
}

async function mine(page, size) {
    return fetchWrapper.get(`${baseUrl}/mine?page=${page}&size=${size}`);
}

async function exchange(voucherId, qty = 1) {
    return fetchWrapper.post(`${apiUrl}/api/public/poin/exchange-to-voucher`, { voucherId, qty });
}

async function claim(accountVoucherId, redeemPin) {
    return fetchWrapper.post(`${baseUrl}/claim`, { accountVoucherId, redeemPin });
}

async function usedVoucher(page, size) {
    return fetchWrapper.get(`${baseUrl}/used-voucher?page=${page}&size=${size}`);
}
