import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, alertService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';

export default function FormPreRegister(props) {

    function handleShowForm(val) {
        props.setShowForm(val);
    }

    function setUserEmail(val) {
        //console.log("EMAIL:", val);
        props.setUserEmail(val);
    }

    function setShowRegister(val) {
        props.setShowRegister(val);
    }

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        referralCode: Yup.string().required('Referral Code is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };



    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ email, referralCode }) {

        return authService.preregister(email, referralCode)
            .then((res) => {
                // success
                //console.log("EMAIL:", email);
                setUserEmail(email);
                handleShowForm(false);
                setShowRegister(true);
            })
            .catch((err) => {
                setShowError(true);
                setErrorApi(err);
            });


    }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="mt-3">&nbsp;</div>
                    <div className="form-group mb-2">

                        <input placeholder="Input your Email here.." name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                    <div className="form-group mb-4">

                        <input placeholder="Input refferal code" name="referralCode" type="text" {...register('referralCode')} className={`form-control ${errors.referralCode ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.referralCode?.message}</div>
                    </div>

                    {
                        (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                            <Alert.Heading>Error!</Alert.Heading>
                            <p>
                                {errorApi}
                            </p>
                        </Alert> : <></>
                    }

                    <button disabled={formState.isSubmitting} className="btn btn-primary" style={{ width: "300px" }}>
                        {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        <i className="fa fa-envelope"></i>&nbsp;
                        Send Registration Code
                    </button>

                </form>
            </div>

        </>
    );
}