import { useState, useEffect, useRef } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import Building from '../artifacts/contracts/Building.sol/Building.json';
import NftBox3 from '../components/nft-box3';
import { ethers } from 'ethers';
import { Contract } from "@ethersproject/contracts";
import img1 from '../images/nft-buildings/1.png';
import img2 from '../images/nft-buildings/2.png';
import img3 from '../images/nft-buildings/3.png';
import img4 from '../images/nft-buildings/4.png';
import img5 from '../images/nft-buildings/5.png';
import img6 from '../images/nft-buildings/6.png';
import img7 from '../images/nft-buildings/7.png';
import img8 from '../images/nft-buildings/8.png';
import img9 from '../images/nft-buildings/9.png';
import img10 from '../images/nft-buildings/10.png';

export default function Mint3() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);
    const [walletAccount, setWalletAccount] = useState(context.walletAccount);
    const [walletChainId, setWalletChainId] = useState(context.walletChainId);
    const [walletActive, setWalletActive] = useState(context.walletActive);
    const [walletLibrary, setWalletLibrary] = useState(context.walletLibrary);
    const [nftPrice, setNftPrice] = useState({});
    const [nftIsMinted, setNftIsMinted] = useState({});
    const maxItems = 10; // kalau production --> 60
    const contractAddress = "0xde110A3Bef3E3127267EE971187f63A159C611A0";
    const ownerAddrress = "0xfFf9c1b8EB3eD9bA51Cca47e548D0E04DD4d0818";
    const cid1 = "ipfs://QmX7cVaxmxWXmY74jPfGgkmBo7hkYvJUfZnEo9titQ49Uq"; //1-5
    const cid2 = "ipfs://QmUvk5ypgxcgZXKgALaRum7Fq4R7kCUWTCNCgRuC4H73L6"; //6-10
    const [loadingProcess, setLoadingProcess] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const refmeta = useRef(null);

    const imageNumIds = Array.from({ length: maxItems }, (_, i) => i + 1);

    function getImgSrc(numId) {
        if (numId == 1) { return img1; };
        if (numId == 2) { return img2; };
        if (numId == 3) { return img3; };
        if (numId == 4) { return img4; };
        if (numId == 5) { return img5; };
        if (numId == 6) { return img6; };
        if (numId == 7) { return img7; };
        if (numId == 8) { return img8; };
        if (numId == 9) { return img9; };
        if (numId == 10) { return img10; };
    }

    function getImgCid(numId) {
        if (numId == 1) { return cid1; };
        if (numId == 2) { return cid1; };
        if (numId == 3) { return cid1; };
        if (numId == 4) { return cid1; };
        if (numId == 5) { return cid1; };
        if (numId == 6) { return cid2; };
        if (numId == 7) { return cid2; };
        if (numId == 8) { return cid2; };
        if (numId == 9) { return cid2; };
        if (numId == 10) { return cid2; };
    }

    async function getAllPrices() {

        if (!(walletActive && walletAccount && walletLibrary)) return;
        //console.log("getAllPrices()");
        const contract = new Contract(contractAddress, Building.abi, walletLibrary);
        const output = await contract.getAllPrices();
        //console.log(output.split("\n"));
        const arr = output.split("\n");
        let priceObj = {};
        for (var i = 0; i < arr.length; ++i) {

            if (i == maxItems) {
                break;
            }

            const data = arr[i].split(":");
            if (data.length == 2) {
                priceObj[data[0]] = ethers.utils.formatEther(data[1]);
            }

        }
        //console.log(priceObj);
        setNftPrice(priceObj);

    }

    async function getAllIsMinteds() {
        if (!(walletActive && walletAccount && walletLibrary)) return;
        const contract = new Contract(contractAddress, Building.abi, walletLibrary);
        const output = await contract.getAllExistingUris();
        const arr = output.split("\n");
        let isMintedObj = {};

        // initial data
        for (var numId = 1; numId <= maxItems; ++numId) {
            isMintedObj[numId.toString()] = false;
        }

        for (var i = 0; i < arr.length; ++i) {
            var filename = arr[i].split("/").pop();
            var data = filename.split(".");
            if (data[0]) {
                isMintedObj[data[0]] = true;
            }
        }
        //console.log("isMintedObj:",isMintedObj);
        setNftIsMinted(isMintedObj);
    }

    async function onMint(cid, strNum) {

        if (!(walletActive && walletAccount && walletLibrary)) return;
        const contract = new Contract(contractAddress, Building.abi, walletLibrary.getSigner());
        const addr = walletAccount;
        const price = nftPrice[strNum];

        console.log(`Mint #${strNum}, to adress: ${addr}, price: ${price} ...`);

        const result = await contract.payToMint(addr, cid, strNum, {
            value: ethers.utils.parseEther(price)
        });

        await result.wait();
        getAllIsMinteds();

    }

    async function btnSetCidCollection() {
        if (!(walletActive && walletAccount && walletLibrary)) return;
        const contract = new Contract(contractAddress, Building.abi, walletLibrary.getSigner());

        const metaurl = refmeta.current.value;

        setLoadingProcess(true);

        const result = await contract.setUriMeta(metaurl);

        await result.wait();
        getAllPrices();

        setLoadingProcess(false);

    }

    async function btnSetPriceClick() {

        if (!(walletActive && walletAccount && walletLibrary)) return;
        const contract = new Contract(contractAddress, Building.abi, walletLibrary.getSigner());

        const theNumber = ref1.current.value;
        const price = ref2.current.value;


        //console.log(price, theNumber);
        //return;

        //console.log(`Mint #${numId}, to adress: ${addr}, price: ${price} ...`);

        setLoadingProcess(true);

        const result = await contract.setPrice(theNumber, ethers.utils.parseEther(price));

        await result.wait();
        getAllPrices();

        setLoadingProcess(false);

    }



    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        setWalletAccount(context.walletAccount);
        setWalletChainId(context.walletChainId);
        setWalletActive(context.walletActive);
        setWalletLibrary(context.walletLibrary);
    }, [context.walletAccount, context.walletChainId, context.walletActive, context.walletLibrary]);

    useEffect(() => {
        getAllPrices();
        getAllIsMinteds();
    }, [walletAccount, walletChainId, walletActive, walletLibrary]);


    return (
        <>

            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <h1>Test Mint Page 3</h1>



                            {
                                (setWalletActive) ? <>

                                    <div className="row">
                                        {

                                            imageNumIds?.map(numId => (
                                                <NftBox3 key={numId}
                                                    cid={getImgCid(numId)}
                                                    imgSrc={getImgSrc(numId)}
                                                    strNum={numId.toString()}
                                                    price={nftPrice[numId.toString()]}
                                                    isMinted={nftIsMinted[numId.toString()]}
                                                    onMint={onMint}

                                                    walletAccount={walletAccount}
                                                    walletChainId={walletChainId}
                                                    walletActive={walletActive}
                                                    walletLibrary={walletLibrary}

                                                ></NftBox3>
                                            ))
                                        }
                                    </div>

                                    {
                                        (walletAccount == ownerAddrress) ?
                                            <>

                                                <h2>Admin Panel</h2>
                                                <div className="row">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div>Number: <input ref={ref1} id="inputNumber" ></input></div>
                                                            <div>New Price: <input ref={ref2} id="inputPrice" ></input></div>

                                                            {
                                                                (loadingProcess) ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    : <>
                                                                        <button style={{ width: "100px" }} onClick={() => btnSetPriceClick()} className="btn btn-warning">Set Price</button>
                                                                    </>
                                                            }

                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="row">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div>Metadata url: <input ref={refmeta} ></input></div>

                                                            {
                                                                (loadingProcess) ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    : <>
                                                                        <button style={{ width: "100px" }} onClick={() => btnSetCidCollection()} className="btn btn-warning">Set CID</button>
                                                                    </>
                                                            }

                                                        </div>
                                                    </div>


                                                </div>

                                            </>
                                            :
                                            <></>
                                    }









                                </> : <>NOPE</>
                            }

                        </>
                        : <NoAccount />
                }
            </div>



        </>
    );
}