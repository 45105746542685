import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, accountService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getValue } from '@testing-library/user-event/dist/utils';

export default function EditProfile() {
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [startDate, setStartDate] = useState(new Date('2000-01-01'));

    const professions = [
        "IT",
        "Pemerintahan",
        "Ekonomi",
        "Logistik",
        "Kesehatan",
        "Pendidikan",
        "Perbankan",
        "Pelajar/mahasiswa",
        "Hukum",
        "Lain-lain"
    ];

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Username harus diisi')
            .min(5, 'Minimal 5 karakter')
            .matches(/^[0-9a-z]+$/ , 'Hanya menggunakan lowercase alphanumeric'),
        name: Yup.string().required('Nama harus diisi'),
        gender: Yup.string().required('Jenis kelamin harus diisi')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    formOptions.defaultValues = {};

    const { register, handleSubmit, formState, setError, setValue, clearErrors } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ username, name, gender, birthDate, city, profession }) {
        return accountService.updateProfile(username, name, gender, birthDate, city, profession)
            .then((res) => {
                // success
                setShowSuccessMsg(true);
                setMessageApi(res.message);
                setShowForm(false);

                authService.me()
                    .then((res) => {
                        context.setAccount(res);
                    })
                    .catch((err) => {
                        context.setAccount(null);
                    });
            })
            .catch((err) => {
                setError("name");
                setShowError(true);
                setErrorApi(err);
            });
    }

    function onChangeBirtDate(bd) {
        //console.log(bd.toISOString().split('T')[0]);
        setStartDate(bd);
        setValue('birthDate', bd);
    }

    function onChangeSelect(e) {
        setValue('profession', e.target.value);
        clearErrors('profession');
    }

    useEffect(() => {
        setAccount(context.account);
        setValue('username', context.account?.username);
        setValue('name', context.account?.name);
        setValue('gender', context.account?.gender);
        setValue('birthDate', context.account?.birthDate);
        setValue('city', context.account?.city);
        if(context.account && context.account.profession != null && context.account.profession != "") {
            setValue('profession', context.account.profession);
        } else {
            setValue('profession', "Lain-lain");
        }

        if (context.account && context.account.birthDate != null && context.account.birthDate != "") {
            setStartDate(new Date(context.account.birthDate));
        } else {
            setStartDate(new Date("2000-01-01"));
        }
        
    }, [context.account]);

    return (
        <>

            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <h1>Edit Profile</h1>
                            <div className="col-md-6">
                                <div className="card">

                                    <div className="card-body">

                                        {
                                            (showSuccessMsg) ?
                                                <>
                                                    <Alert variant="success" onClose={() => setShowSuccessMsg(false)} dismissible>
                                                        <Alert.Heading>Success!</Alert.Heading>
                                                        <p>
                                                            {messageApi}
                                                        </p>
                                                    </Alert>
                                                </>
                                                :
                                                ""
                                        }

                                        {
                                            (showForm) ?
                                                <>
                                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Username</label>
                                                            <input maxLength={20} name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                                                            <div className="invalid-feedback">{errors.username?.message}</div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Nama Lengkap</label>
                                                            <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                                            <div className="invalid-feedback">{errors.name?.message}</div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Jenis Kelamin</label>
                                                            <div>
                                                                <div className="form-check form-check-inline">
                                                                    <input type="radio" name="gender" value="M" {...register('gender')} className={`form-check-input ${errors.name ? 'is-invalid' : ''}`} />
                                                                    <label className="form-check-label">Laki-Laki</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input type="radio" name="gender" value="F" {...register('gender')} className={`form-check-input ${errors.name ? 'is-invalid' : ''}`} />
                                                                    <label className="form-check-label">Perempuan</label>
                                                                </div>
                                                            </div>

                                                            <div className="invalid-feedback">{errors.gender?.message}</div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Tanggal Lahir</label>

                                                            <DatePicker className="form-control" 
                                                               
                                                                showYearDropdown
                                                                
                                                                dateFormat="yyyy-MM-dd" 
                                                                selected={startDate} 
                                                                onChange={onChangeBirtDate} 
                                                                onKeyDown={(e) => { e.preventDefault(); }} />


                                                            <div className="invalid-feedback">{errors.birthDate?.message}</div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Kota</label>
                                                            <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                                            <div className="invalid-feedback">{errors.city?.message}</div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Profesi</label>
                                                            <select name="profession" onChange={onChangeSelect} {...register('profession')} className={`form-control ${errors.profession ? 'is-invalid' : ''}`}>
                                                                
                                                                {professions.map((p) => (
                                                                    <option key={p} value={p}>{p}</option>
                                                                ))}
                                                            </select>
                                                            <div className="invalid-feedback">{errors.profession?.message}</div>
                                                        </div>

                                                        {
                                                            (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                                                <Alert.Heading>Error!</Alert.Heading>
                                                                <p>
                                                                    {errorApi}
                                                                </p>
                                                            </Alert> : <></>
                                                        }

                                                        <button disabled={formState.isSubmitting} className="btn btn-primary">
                                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                                            Update Profile
                                                        </button>

                                                    </form>
                                                </> : <>
                                                    <div className="mt-4">
                                                        <a href="/view-profile" >Back to Profile</a>
                                                    </div>
                                                </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </>
                        : <NoAccount />
                }
            </div>


        </>
    );
}