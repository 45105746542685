import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import '../profile.css';
import avatarImg from '../images/avatar-black.png';
import imgMoney from '../images/money.png';
import imgMoneyT from '../images/money_t.png';
import { NftAddress } from '../components/NftAddress';
import imgCheck from '../images/check.svg';

export default function ViewProfile() {

    const { t } = useTranslation();
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    return (
        <>
            
            <div className="pageContainer">
                {
                    (account) ?

                        <>
                            
                            <div className="divBreadcrum">
                                <span>Home</span>
                                <span style={{margin: "0 10px"}}>/</span>
                                <span style={{fontWeight: 700}}>Profile</span>
                            </div>


                            <div className="divImgAvatar">
                                <img className="img-fluid" src={avatarImg} alt="User Avatar" width={200} />
                            </div>
                            <div className="divInfoAvatar">
                                <div>
                                    <div className="profileAvatarName">
                                        {account.username}

                                        <img style={{marginLeft: "10px"}} src={imgCheck} alt="Profile" />

                                        <a title="Edit Profile.." href="edit-profile" className="my-edit-icon ml-4">
                                            <i className="fa fa-edit"></i>
                                        </a>
                                    </div>

                                    <div className="clearfix"></div>
                                </div>

                                <div style={{marginBottom: "30px"}}>
                                    <img src={imgMoney} alt="Money" style={{marginRight: "5px"}}/>
                                    <span style={{fontSize: "18px"}}>{account.totalPoin} Point</span>
                                     
                                    <span style={{margin: "0 10px"}}>&nbsp;</span>

                                    <img src={imgMoneyT} alt="Money T"  style={{marginRight: "5px"}}/> 
                                    <span style={{fontSize: "18px"}}>XX T-Coin</span>
                                    
                                </div>

                                <div className="badgesTitle">
                                    Nama Lengkap
                                </div>
                                <div>
                                    {account.name}
                                </div>

                                <div className="badgesTitle">
                                    Email
                                </div>
                                <div>
                                    {account.email}
                                </div>

                                <div className="badgesTitle">
                                    Solana Wallet Address
                                    <a title="Edit Wallet Address.." href="edit-wallet" className="my-edit-icon ml-4">
                                        <i className="fa fa-edit"></i>
                                    </a>
                                </div>
                                <div>
                                    <NftAddress
                                        text={account.solAddress}
                                        itemId={account.id}
                                        tagName="A"
                                    ></NftAddress>
                                </div>

                                <div className="badgesTitle">
                                    Ethereum Wallet Address
                                    <a title="Edit Wallet Address.." href="edit-wallet-eth" className="my-edit-icon ml-4">
                                        <i className="fa fa-edit"></i>
                                    </a>
                                </div>
                                <div>
                                    <NftAddress
                                        text={account.ethAddress}
                                        itemId={account.id}
                                        tagName="B"
                                    ></NftAddress>
                                </div>





                            </div>

                            <div className="clearfix"></div>

                        </>

                        : <NoAccount></NoAccount>
                }

            </div>
        </>
    );
}