import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, alertService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';

export default function FormResetPassword(props) {

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [userEmail, setUserEmail] = useState(props.userEmail);

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        resetCode: Yup.string().required('Reset Code is required'),
        newPassword: Yup.string().required('New Password is required'),
        newPasswordConfirmation: Yup.string().required('New Password Confirmation is required')
            .oneOf([Yup.ref('newPassword'), null], 'New Password Confirmation must match')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };



    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ email, resetCode, newPassword }) {

        return authService.resetPassword(email, resetCode, newPassword)
            .then((res) => {
                // success
                setShowSuccessMsg(true);
                setMessageApi(res.message);
            })
            .catch((err) => {
                setShowError(true);
                setErrorApi(err);
            });


    }

    useEffect(() => {
        //console.log("EMAIL:", props.userEmail);
        setUserEmail(props.userEmail);
        setValue('email', userEmail);
    }, [props.userEmail]);

    return (
        <>
            <div >


                {
                    (showSuccessMsg) ?
                        <>
                            <Alert variant="success" dismissible>
                                <Alert.Heading>Success!</Alert.Heading>
                                <p>
                                    {messageApi}
                                </p>
                            </Alert>
                            <div className="mt-4">
                                <a href="/login" className="btn btn-sm btn-info">Login</a>
                                <a href="/" className="btn btn-sm btn-info mx-2">Back to Home</a>
                            </div>
                        </>
                        : <>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="mt-3">&nbsp;</div>
                                <div className="form-group mb-2">
                                    <div><span style={{ fontWeight: "700" }}>Email: </span> {userEmail}</div>
                                    <input name="email" type="hidden" {...register('email')} />
                                </div>

                                <div className="form-group mb-2">
                                  
                                    <input placeholder="Reset Code" name="resetCode" type="text" {...register('resetCode')} className={`form-control ${errors.resetCode ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.resetCode?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    
                                    <input placeholder="New Password" name="newPassword" type="password" {...register('newPassword')} className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.newPassword?.message}</div>
                                </div>

                                <div className="form-group mb-4">
                                    
                                    <input placeholder="New Password Confirmation" name="newPasswordConfirmation" type="password" {...register('newPasswordConfirmation')} className={`form-control ${errors.newPasswordConfirmation ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.newPasswordConfirmation?.message}</div>
                                </div>


                                {
                                    (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                        <Alert.Heading>Error!</Alert.Heading>
                                        <p>
                                            {errorApi}
                                        </p>
                                    </Alert> : <></>
                                }

                                <button disabled={formState.isSubmitting} className="btn btn-primary" style={{ width: "300px" }}>
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Reset Password
                                </button>

                            </form>
                        </>
                }

            </div>


        </>
    );
}