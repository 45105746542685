import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, accountService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';

export default function EditWallet() {
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);
    const [showForm, setShowForm] = useState(true);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        solAddress: Yup.string().required('Wallet Address is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    formOptions.defaultValues = {
        solAddress : ""
    };
    const { register, handleSubmit, formState, setError, setValue } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ solAddress }) {
        return accountService.updateSolAddress(solAddress)
            .then((res) => {
                // success
                setShowSuccessMsg(true);
                setMessageApi(res.message);
                setShowForm(false);
            })
            .catch((err) => {
                setError("solAddress");
                setShowError(true);
                setErrorApi(err);
            });
    }

    useEffect(() => {
        setAccount(context.account);
        setValue('solAddress', context.account?.solAddress);
    }, [context.account]);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <h1>Edit Solana Wallet Address</h1>
                            <div className="col-md-6">
                                <div className="card">

                                    <div className="card-body">

                                        {
                                            (showSuccessMsg) ?
                                                <>
                                                    <Alert variant="success" onClose={() => setShowSuccessMsg(false)} dismissible>
                                                        <Alert.Heading>Success!</Alert.Heading>
                                                        <p>
                                                            {messageApi}
                                                        </p>
                                                    </Alert>
                                                </>
                                                :
                                                ""
                                        }

                                        {
                                            (showForm) ?
                                                <>
                                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Wallet Address</label>
                                                            <input placeholder="Input your wallet address here.." name="solAddress" type="text" {...register('solAddress')} className={`form-control ${errors.solAddress ? 'is-invalid' : ''}`} />
                                                            <div className="invalid-feedback">{errors.solAddress?.message}</div>
                                                        </div>

                                                        {
                                                            (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                                                <Alert.Heading>Error!</Alert.Heading>
                                                                <p>
                                                                    {errorApi}
                                                                </p>
                                                            </Alert> : <></>
                                                        }

                                                        <button disabled={formState.isSubmitting} className="btn btn-warning">
                                                            {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}

                                                            Update Wallet Address
                                                        </button>

                                                    </form>
                                                </> : <>
                                                    <div className="mt-4">
                                                        <a href="/view-profile" className="btn btn-sm btn-info mx-2">Back to Profile</a>
                                                    </div>
                                                </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </>
                        : <NoAccount />
                }
            </div>


        </>
    );
}