import { useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from '../components/useWindowDimensions ';

export default function Test() {

    const { height, width } = useWindowDimensions();
    const { t, i18n } = useTranslation();

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                <h1>Test</h1>
                <div>width: {width} px</div>
                <div>height: {height} px</div>
                <div>Language: {t('language')}</div>
            </div>
            
        </>
    );
}