import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import walletImg from '../images/wallet.png'
import { Dropdown } from 'react-bootstrap';
import styled from "styled-components";
import { BigNumber, ethers } from 'ethers';

const DivImgWallet = styled.div`
  width: 30px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

const ConnectWallet = (props) => {


    const injectedConnector = new InjectedConnector({
        supportedChainIds: [
            1,    // ETH Main
            56,   // BNB Main
            137,  // MATIC Main
            5,    // Goerli Testnet
            97,   // tBNB Testnet
            80001 // Mumbai Testnet
        ],
    })
    const { chainId, account, activate, active, library } = useWeb3React();
    const [balance, setBalance] = useState("");
    const [networkName, setNetworkName] = useState("");
    const [networkCoin, setNetworkCoin] = useState("");

    const onClick = () => {
        activate(injectedConnector)
    }

    function getChainName() {

        if (chainId == 1) {
            return "ETH Mainnet";

        } else if (chainId == 56) {
            return "BNB Mainnet";

        } else if (chainId == 137) {
            return "MATIC Mainnet";

        } else if (chainId == 5) {
            return "Goerli Testnet";

        } else if (chainId == 97) {
            return "tBNB Testnet";

        } else if (chainId == 80001) {
            return "Mumbai Mainnet";

        } else {
            return "";
        }
    }

    function getCoinName() {

        if (chainId == 1) {
            return "ETH";

        } else if (chainId == 56) {
            return "BNB";

        } else if (chainId == 137) {
            return "MATIC";

        } else if (chainId == 5) {
            return "ETH";

        } else if (chainId == 97) {
            return "tBNB";

        } else if (chainId == 80001) {
            return "MATIC";

        } else {
            return "";
        }
    }

    useEffect(() => {

        if (!(active && account && library)) {
            props.setWalletAccount(null);
            props.setWalletChainId(null);
            props.setWalletActive(null);
            props.setWalletLibrary(null);
            return;
        }
        console.log("ConnectWallet Account:", account);
        props.setWalletAccount(account);
        props.setWalletChainId(chainId);
        props.setWalletActive(active);
        props.setWalletLibrary(library);

        library.getBalance(account).then((result) => {
            setBalance((result / 1e18).toFixed(4))
        });

        setNetworkName(getChainName());
        setNetworkCoin(getCoinName());

    }, [chainId, account, active, library]);

    return (
        <>

            {active ?
                <>

                    <div style={{padding: "20px"}}>
                        <div style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "150px",

                            fontFamily: "monospace"

                        }}>{account}</div>
                        <div></div>
                        <div>{networkName}</div>
                        <div>{balance} {networkCoin}</div>

                    </div>

                </>

                :
                <>

                    {
                        /**
                         * <DivImgWallet onClick={onClick} >
                <img alt="Wallet" src={walletImg} className="img-fluid" />
            </DivImgWallet>
                         */
                    }

                    <div style={{padding: "20px 20px 10px 20px"}}>
                        <button className="btn btn-primary" onClick={onClick}>Connect Wallet</button>
                    </div>

                </>

            }

        </>

    )
}


export default ConnectWallet;