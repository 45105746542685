import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, alertService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const MyReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        
        localStorage.setItem('contact_form_token', token);
        
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    //return <button type="button" onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
    return "";
};

export default function FormContact(props) {

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [userEmail, setUserEmail] = useState(props.userEmail);

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email().required('Email is required'),
        message: Yup.string().required('Message is required')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };



    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ name, email, message }) {

        const contact_form_token = localStorage.getItem("contact_form_token");

        return authService.contactUs(name, email, message, contact_form_token)
            .then((res) => {
                // success
                setShowSuccessMsg(true);
                setMessageApi(res.message);
            })
            .catch((err) => {
                setShowError(true);
                setErrorApi(err);
            });


    }

    useEffect(() => {

    }, []);

    return (
        <>
            <div >


                {
                    (showSuccessMsg) ?
                        <>
                            <Alert variant="success" dismissible>
                                <Alert.Heading>Success!</Alert.Heading>
                                <p>
                                    {messageApi}
                                </p>
                            </Alert>

                        </>
                        : <>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="mt-3">&nbsp;</div>

                                <div className="form-group mb-2">
                                    <label>Name</label>
                                    <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    <label>Email</label>
                                    <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    <label>Message</label>
                                    <textarea name="message" type="text" {...register('message')} className={`form-control ${errors.message ? 'is-invalid' : ''}`}></textarea>
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                </div>

                                {
                                    (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                        <Alert.Heading>Error!</Alert.Heading>
                                        <p>
                                            {errorApi}
                                        </p>
                                    </Alert> : <></>
                                }

                                <div>
                                    <div id="divGoogleReCaptchaContact"></div>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={reCaptchaKey}
                                        
                                        container={{
                                            element: "divGoogleReCaptchaContact",
                                            parameters: {

                                                theme: 'dark'
                                            }
                                        }}
                                        
                                    >
                                        <MyReCaptchaComponent />
                                    </GoogleReCaptchaProvider>

                                </div>

                                <div className="mb-4"></div>

                                <button disabled={formState.isSubmitting} className="btn btn-primary" >
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Submit
                                </button>

                            </form>
                        </>
                }

            </div>


        </>
    );
}