import { useState, useEffect } from 'react';

export default function NoAccount() {

    const [showElem, setShowElem] = useState(false);
    //const [currentUrl, setCurrentUrl] = useState("");
    const [loginUrl, setLoginUrl] = useState("");

    useEffect(() => {

        if (localStorage.getItem("userTpWeb") !== null) {
            setShowElem(false);
        } else {
            setShowElem(true)
        }
        
        //console.log(encodeURIComponent(window.location.href));
        //setCurrentUrl(window.location.href);
        setLoginUrl("/login?returnUrl=" + encodeURIComponent(window.location.href));

    }, []);

    return (
        <>
            {
                (showElem) ? <>
                    <h2>Please login to access this page!</h2>
                    <div>
                        <a href={loginUrl} className="btn btn-primary">Go To Login Page</a>
                    </div>
                </> : ""
            }

        </>

    );
}