import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { voucherService } from '../services';
import ReactPaginate from 'react-paginate';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Alert } from 'react-bootstrap';

export default function UsedVoucher() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(8);
    const [dataItems, setDataItems] = useState(null);

    const [activeItem, setActiveItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    
    const getIndex = (page, size) => {
        voucherService.usedVoucher(page, size).then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const handlePageClick = (p) => {
        const page = p.selected;
        setCurPage(page);
        getIndex(page, perPage);
    };

    const handleBoxVoucherClick = (item) => {
        setShowAlert(false);
        setAlertMessage("");
        const collection = document.getElementsByClassName("divVoucherBox");
        Array.prototype.forEach.call(collection, function (el) {
            el.classList.remove("selected");
        });

        setActiveItem(item);
        //console.log("voucher-" + item.id);
        let elem = document.getElementById("voucher-" + item.id);
        if (elem) {
            elem.classList.add("selected");
        }
    };

    const formatDate = (dateVal) => {

        var date = new Date(dateVal);

        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        //var seconds = date.getSeconds();

        //return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    }



    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);
    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <div className="myPageTitle">Voucher yang Sudah Digunakan</div>
                            {
                                (dataItems) ? <>

                                    <div className="row">
                                        <div className="col">
                                            {
                                                dataItems.map((item, i) => {
                                                    ++i;

                                                    return (
                                                        <div key={item.id} className="divVoucherBox" id={`voucher-${item.id}`} onClick={() => handleBoxVoucherClick(item)}>
                                                            <div><img className="img-fluid" src={item.Voucher.image} alt="" /></div>
                                                            <div>{item.Voucher.code}</div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="clearfix"></div>

                                            <ReactPaginate
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={perPage}
                                                pageCount={pageCount}
                                                renderOnZeroPageCount={null}

                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"

                                            />
                                        </div>

                                        <div className="col">
                                            {
                                                (activeItem) ?
                                                    <>
                                                        <div>{activeItem.Voucher.name}</div>
                                                        <div><span className="voucherLabel">Code:</span> {activeItem.Voucher.code}</div>
                                                        <div><span className="voucherLabel">SN:</span> {activeItem.serialNumber}</div>

                                                        <div className="voucherDescription">
                                                            {activeItem.Voucher.description}
                                                        </div>

                                                        <div className="mt-2">
                                                            <div><span className="voucherLabel">Telah Diklaim pada:</span> {formatDate(activeItem.claimedAt)}</div>
                                                            <div><span className="voucherLabel">Nama Petugas:</span> {activeItem.StaffAccount.name}</div>
                                                        </div>


                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (showAlert) ? <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                                                                <Alert.Heading></Alert.Heading>
                                                                <p>{alertMessage}</p>
                                                            </Alert> : ""
                                                        }

                                                        <div>
                                                            <i>Click item on the left to display detail information</i>
                                                        </div>

                                                    </>
                                            }

                                        </div>

                                    </div>






                                </>
                                    : <>
                                        <div>No data found.</div>
                                    </>
                            }

                            

                        </>
                        : <NoAccount />
                }

            </div>
        </>
    );
}