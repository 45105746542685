import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { poinService } from '../services';
import ReactPaginate from 'react-paginate';

export default function PoinHistories() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [dataItems, setDataItems] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);

    const getIndex = (page, size) => {
        poinService.histories(page, size).then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const handlePageClick = (p) => {
        const page = p.selected;
        setCurPage(page);
        getIndex(page, perPage);
    };

    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    function strTimeSince(dtime) {
        return timeSince(new Date(dtime)) + " ago";
    }

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);
    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <div className="myPageTitle">Poin</div>
                            {
                                (dataItems) ? <>
                                    {
                                        dataItems.map((item, i) => {
                                            ++i;

                                            return (
                                                <div key={item.id} className="divBlackCorner">
                                                    <div className="profileSubTitle">
                                                        {item.title}
                                                        <span style={{ fontSize: "0.8rem", float: "right", color: "#CCCCCC" }} className="date timeago" title={item.createdAt}>
                                                            {strTimeSince(item.createdAt)}
                                                        </span>
                                                    </div>
                                                    <div className="">
                                                        <div>
                                                            {
                                                                (item.poinValue > 0) ?
                                                                    <span className="badge bg-success">
                                                                        +{item.poinValue}
                                                                    </span> :
                                                                    <span className="badge bg-danger">
                                                                        {item.poinValue}
                                                                    </span>
                                                            }
                                                            <span className="badge bg-yellow ml-2">
                                                                {item.totalPoin}
                                                            </span>
                                                        </div>

                                                        <div style={{fontSize: "0.9rem", paddingTop: "5px", color: "#CCCCCC"}}>{item.description}</div>

                                                    </div>


                                                </div>
                                            )
                                        })
                                    }

                                    <div>&nbsp;</div>

                                    <ReactPaginate
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={perPage}
                                        pageCount={pageCount}
                                        renderOnZeroPageCount={null}

                                        previousLabel="<"
                                        nextLabel=">"
                                        breakLabel="..."
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"

                                    />

                                </>
                                    : <>
                                        <div>No data found.</div>
                                    </>
                            }


                        </>
                        : <NoAccount />
                }
            </div>
        </>
    );
}