import { useState, useEffect } from 'react';
import FormLostPassword from "../components/form-lost-password";
import FormResetPassword from '../components/form-reset-password';
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import wheelWhite from '../images/wheel-icon.svg';

import '../login-v2.css';

export default function LostPassword() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);
    const navigate = useNavigate();

    const [showLostPassword, setShowLostPassword] = useState(true);
    const [userEmail, setUserEmail] = useState(null);
    const [showResetPassword, setShowResetPassword] = useState(false);

    useEffect(() => {
        if (context.account) {
            navigate("/view-profile");
        }

        setAccount(context.account);
    }, [context.account]);

    return (
        <>
            <div className="login-page-container">
                <div className="login-page-bg">
                    <div className="pageContainer fw100">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">

                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="login-vertical-center">
                                    <div style={{ margin: "auto", width: "300px" }}>
                                        <div style={{textAlign: "center"}}>
                                            <a href="/"><img src={wheelWhite} alt="Wheel" className="img-fluid" width={250}/></a>
                                        </div>

                                        {
                                            (showLostPassword) ? <FormLostPassword
                                                setShowForm={setShowLostPassword}
                                                setUserEmail={setUserEmail}
                                                setShowResetPassword={setShowResetPassword}
                                            ></FormLostPassword> : <></>
                                        }

                                        {
                                            (showResetPassword) ? <FormResetPassword userEmail={userEmail}></FormResetPassword> : <></>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </>
    );
}