import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import './adminlte.min.css';
import './index.css';
import App from './App';
import Home from './pages/home';
import Expenses from './pages/expenses';
import Invoices from './pages/invoices';
import Invoice from './pages/invoice';
import Contact from './pages/contact';
import About from './pages/about';
import Login from './pages/login';
import Register from './pages/register';
import LostPassword from './pages/lost-password';
import Profile from './pages/profile';
import Badge from './pages/badge';
import EditWallet from './pages/edit-wallet';
import EditWalletEth from './pages/edit-wallet-eth';
import EditWalletWeb from './pages/edit-wallet-web';
import EditProfile from './pages/edit-profile';
import EditProfileWeb from './pages/edit-profile-web';
import ChangePassword from './pages/change-password';
import PoinHistories from './pages/poin-histories';
import PoinExchange from './pages/poin-exchange';
import Features from './pages/features';
import Gallery from './pages/gallery';
import Community from './pages/community';
import ViewProfile from './pages/view-profile';
import ViewProfileWeb from './pages/view-profile-web';
import Test from './pages/test';
import LastLogin from './pages/last-login';
import MyVoucher from './pages/my-voucher';
import MerchantRedeemVoucher from './pages/merchant-rv';
import UsedVoucher from './pages/used-voucher';
import PoinIn from './pages/poin-in';
import PoinInWeb from './pages/poin-in-web';
import VoucherUnused from './pages/voucher-unused';
import VoucherUnusedWeb from './pages/voucher-unused-web';
import MyNft from './pages/my-nft';
import Mint3 from './pages/mint3';
import NftBuildings from './pages/nft-buildings';
import NftBuilding from './pages/nft-building';
import ContactUs from './pages/contact-us';
import PrivacyPolicy from './pages/privacy-policy';


import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route index element={<Home />}/>
          <Route path="expenses" element={<Expenses />} />
          <Route path="invoices" element={<Invoices />} >
            <Route
              index
              element={
                <main style={{ padding: "1rem" }}>
                  <p>Select an invoice</p>
                </main>
              }
            />
            <Route path=":invoiceId" element={<Invoice />} />
          </Route>
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="lost-password" element={<LostPassword />} />
          <Route path="profile" element={<Profile />} />
          <Route path="badge" element={<Badge />} />
          <Route path="edit-wallet" element={<EditWallet />} />
          <Route path="edit-wallet-eth" element={<EditWalletEth />} />
          <Route path="edit-wallet-web" element={<EditWalletWeb />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="edit-profile-web" element={<EditProfileWeb />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="poin-histories" element={<PoinHistories />} />
          <Route path="poin-exchange" element={<PoinExchange />} />
          <Route path="features" element={<Features />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="community" element={<Community />} />
          <Route path="view-profile" element={<ViewProfile />} />
          <Route path="view-profile-web" element={<ViewProfileWeb />} />
          <Route path="test" element={<Test />} />
          <Route path="last-login" element={<LastLogin />} />
          <Route path="my-voucher" element={<MyVoucher />} />
          <Route path="merchant-rv" element={<MerchantRedeemVoucher />} />
          <Route path="used-voucher" element={<UsedVoucher />} />
          <Route path="poin-in" element={<PoinIn />} />
          <Route path="poin-in-web" element={<PoinInWeb />} />
          <Route path="voucher-unused" element={<VoucherUnused />} />
          <Route path="voucher-unused-web" element={<VoucherUnusedWeb />} />
          <Route path="my-nft" element={<MyNft />} />
          <Route path="mint3" element={<Mint3 />} />
          <Route path="nft-buildings" element={<NftBuildings />} />
          <Route path="nft-building/:numId" element={<NftBuilding />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Sorry, the page you were looking for is not here!</p>
              </main>
            }
          />
        </Route>


      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
