import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { voucherService } from '../services';
import { Alert, Modal, Tabs, Tab } from 'react-bootstrap';
import QRCode from 'qrcode';
import useWindowDimensions from '../components/useWindowDimensions ';

export default function VoucherUnusedWeb() {

    const { height, width } = useWindowDimensions();
    const [panelHeight, setPanelHeight] = useState(210);
    const [panelHeightR, setPanelHeightR] = useState(150);
    const [panelHeightR2, setPanelHeightR2] = useState(150);
    const { t } = useTranslation();
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(10000);
    const [dataItems, setDataItems] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);
    const [curPageOut, setCurPageOut] = useState(0);
    const [pageCountOut, setPageCountOut] = useState(0);
    const [dataItemsOut, setDataItemsOut] = useState(null);

    const [activeItem, setActiveItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showQr, setShowQr] = useState(false);
    const [imgSrc, setImgSrc] = useState("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABLtJREFUeF7tnNuO5DAIRKf//6N7pX3auKU+KhWQDFvzasdAHcDOpef1fr/fP/lbo8ArQNew/BtIgO7iGaDLeAZogG5TYFk82UMDdJkCy8JJhQboMgWWhZMKDdBlCiwLJxUaoMsUWBZOKjRAlymwLBy7Ql+v16gk5+vb0776epeuvzs+VdwAPRKSEkYVWJ2vJuS5foAG6DUn7m5J1DKpQuj6u+Mj/9sr1G0ZHw6KFUT2CRC1XFpfBgDxyeu53xRRhqsOBaj3zV75HjqdwWpCpUKhxFRB1Yql9WmcKv4cT8sV9zgCqgpKQGmc/KHrqeKnE6a95XYHrAqubgnq+pQgasLSeuOn3AC9Sh6gYkuvFiwVevxUprtCqSUREPf6u+Nb13JdIO71AQoKTrfMAD0UoJZ2dwaTfwH6MKAErDuhutenhFu3hwbo8far++F8dwYHaIB+7WLqoaw7YR/XclWH6GE6PbqjilX9qV7PjY/8b3+WSw7QuCqoOr/aPq0XoOIb/QClHgYpVy2gm8HV/lSv58ZHHaC85ZJBd9w9tLjXu/7T9WZ9+f80Qz3lUUA07gJxryf/3PEAbX69dnfCqgmSlismhCqwOv/2ClUdzvxeBewK7XUvq6sKBKiq2MPnB+jDAanu2UDVG+/uUyPdlpwC0Xw6pFD8arxkjwAHKHzURgIHaPMvvqniUqHFz3LVFkQtRgWkzv/vKpQEJ4AkmPowm+yRv1Th6jjZqx6391ByiAQOUFJQGw/QQy+1AulQpOHwZwdogPpZ9O8K1JK7DzEUjbsl0PoUn3p9e4WSQwF6VUhNoI9Do/tdLgGj8QAN0IsC6qHETaDqBH1cyyVBVQHVAMm+C4BaINmncfJPHbf3UHI4QK//3JISRAVYvocG6HdgpI8LMEAPBaiDUEURMBp/HFByqFowsqcCUNejeKbPAOUVSoKQAN0APgIWX9/Ro0CKX42P5pM9+1CEBkBACoASwq0I8j9Am/coFUAqlBR72DhVsNoBaH53gqj2x/fQbv4BelW4fQ8N0KsCtCenQosPXaqg1CHo0FZ9n2pXqBqQW7GU4dUCVsenJoyqV4Ae3+W6hx4CEKCHQqnQ7ymTCk2FHsdk8b+UUEuilkcV6rY02jPV9avXI/3KK1QNGB0Uf2Ht2q8GUL0e6uV+U1R97E6FErLhPdStkAB9OFBqOe59o7u+Kh91JBpX7anz2/dQV3ASyF1fFmx4T5f9695DXcEDVEOaCtX0+lETrPpMQe6uA0r3qSSIuqe766nX0/wABYW6E4QAqeMBGqDao7/pQ1F3RanxUAtXK5Dm//oKdR9EkEAExAVM66v+BaioWHUHCFDxdVc1gOr1AjRAv/aU9pYrdrSP6XQjT+ure5xagep88td9EBGgh8IqIHV+gJpfRKRCKYWOcbclkjl3/QAlhQGoeLk8XW1x6nzZIfECd48kc+V7KBl0x1VA6nzXP7o+QB92aCFgNB6gAUo5chm3W65kLZPbFQjQdolnDQTorN7t1gK0XeJZAwE6q3e7tQBtl3jWQIDO6t1uLUDbJZ41EKCzerdbC9B2iWcNBOis3u3WArRd4lkDATqrd7u1AG2XeNZAgM7q3W4tQNslnjXwB5qt3v9UMuYJAAAAAElFTkSuQmCC");


    const getIndex = (page, size) => {
        voucherService.mine(page, size).then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const getIndexOut = (page, size) => {
        voucherService.usedVoucher(page, size).then(x => {
            setDataItemsOut(x.result);
            setCurPageOut(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCountOut(x.meta.pageCount);
        });
    }

    const handleBoxVoucherClick = (item) => {
        setShowAlert(false);
        setAlertMessage("");
        const collection = document.getElementsByClassName("divVoucherBox");
        Array.prototype.forEach.call(collection, function (el) {
            el.classList.remove("selected");
        });

        setActiveItem(item);
        //console.log("voucher-" + item.id);
        let elem = document.getElementById("voucher-" + item.id);
        if (elem) {
            elem.classList.add("selected");
        }
    };

    const handleShowQr = (item) => {
        setShowQr(true);

        let url = process.env.REACT_APP_BASE_WEB
            + "/merchant-rv?lang=id&noheaderfooter=1&accountVoucherId=" + item.id
            + "&secretkey=xxx"

        QRCode.toDataURL(url,
            {
                margin: 0,
                scale: 4
            })
            .then(url => {
                setImgSrc(url);
            })
            .catch(err => {
                console.error(err)
            })

    };
    const handleCloseQr = () => {
        setShowQr(false)
    };

    const formatDate = (dateVal) => {

        var date = new Date(dateVal);

        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        //var seconds = date.getSeconds();

        //return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    }

    const handleSelectTab = (e) => {
        setShowAlert(false);
        setAlertMessage("");
        const collection = document.getElementsByClassName("divVoucherBox");
        Array.prototype.forEach.call(collection, function (el) {
            el.classList.remove("selected");
        });
        setActiveItem(null);
    }


    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);
        getIndexOut(curPage, perPage);
        setPanelHeight(height - 100);
        setPanelHeightR(height - 165);
        setPanelHeightR2(height - 110);
    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>

                            <Tabs
                                defaultActiveKey="unusedVoucher"
                                id="poin-tab"
                                onSelect={handleSelectTab}
                            >
                                <Tab eventKey="unusedVoucher" title="Aktif">
                                    {
                                        (dataItems) ? <>

                                            <div className="row">
                                                <div className="col scroll" style={{ height: `${panelHeight}px` }}>
                                                    {
                                                        dataItems.map((item, i) => {
                                                            ++i;

                                                            return (
                                                                <div key={item.id} className="divVoucherBox" id={`voucher-${item.id}`} onClick={() => handleBoxVoucherClick(item)}>
                                                                    <div><img className="img-fluid" src={item.Voucher.image} alt="" /></div>
                                                                    <div className="globalItemCode">{item.Voucher.code}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="clearfix"></div>


                                                </div>

                                                <div className="col">
                                                    {
                                                        (activeItem) ?
                                                            <>
                                                                <div className="boxVoucherInformation" style={{ height: `${panelHeightR}px` }}>
                                                                    <div style={{ fontWeight: "bold" }}>{activeItem.Voucher.name}</div>
                                                                    <div><span className="voucherLabel">{t('code')}:</span> {activeItem.Voucher.code}</div>
                                                                    <div><span className="voucherLabel">{t('merchant')}:</span> {activeItem.Voucher.Merchant?.name}</div>
                                                                    <div><span className="voucherLabel">SN:</span> {activeItem.serialNumber}</div>

                                                                    <div className="voucherDescription">
                                                                        {activeItem.Voucher.description}
                                                                    </div>
                                                                </div>
                                                                <div className="my-3 text-right">
                                                                    <span className="btn btn-warning" onClick={() => handleShowQr(activeItem)}>Tampilkan Kode Klaim</span>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (showAlert) ? <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                                                                        <Alert.Heading></Alert.Heading>
                                                                        <p>{alertMessage}</p>
                                                                    </Alert> : ""
                                                                }

                                                                <div>
                                                                    <i>{t('click.item.description')}</i>
                                                                </div>

                                                            </>
                                                    }

                                                </div>

                                            </div>

                                        </>
                                            : <>
                                                <div>No data found.</div>
                                            </>
                                    }
                                </Tab>
                                <Tab eventKey="usedVoucher" title="Sudah Digunakan">
                                    {
                                        (dataItemsOut) ? <>

                                            <div className="row">
                                                <div className="col scroll" style={{ height: `${panelHeight}px` }}>
                                                    {
                                                        dataItemsOut.map((item, i) => {
                                                            ++i;

                                                            return (
                                                                <div key={item.id} className="divVoucherBox" id={`voucher-${item.id}`} onClick={() => handleBoxVoucherClick(item)}>
                                                                    <div><img className="img-fluid" src={item.Voucher.image} alt="" /></div>
                                                                    <div>{item.Voucher.code}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="clearfix"></div>


                                                </div>

                                                <div className="col">
                                                    {
                                                        (activeItem) ?
                                                            <>
                                                                <div className="boxVoucherInformation" style={{ height: `${panelHeightR2}px` }}>
                                                                    <div style={{ fontWeight: "bold" }}>{activeItem.Voucher.name}</div>
                                                                    <div><span className="voucherLabel">{t('code')}:</span> {activeItem.Voucher.code}</div>
                                                                    <div><span className="voucherLabel">{t('merchant')}:</span> {activeItem.Voucher.Merchant?.name}</div>
                                                                    <div><span className="voucherLabel">SN:</span> {activeItem.serialNumber}</div>

                                                                    <div className="mt-2">
                                                                        <div><span className="voucherLabel">Telah Diklaim pada:</span> {formatDate(activeItem.claimedAt)}</div>
                                                                        <div><span className="voucherLabel">Nama Petugas:</span> {activeItem.StaffAccount?.name}</div>
                                                                    </div>
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (showAlert) ? <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                                                                        <Alert.Heading></Alert.Heading>
                                                                        <p>{alertMessage}</p>
                                                                    </Alert> : ""
                                                                }

                                                                <div>
                                                                    <i>{t('click.item.description')}</i>
                                                                </div>

                                                            </>
                                                    }

                                                </div>

                                            </div>

                                        </>
                                            : <>
                                                <div>No data found.</div>
                                            </>
                                    }
                                </Tab>
                            </Tabs>




                            <Modal show={showQr} onHide={handleCloseQr} className="qrmodal">
                                <Modal.Header closeButton>
                                    <Modal.Title></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="text-center">
                                        <img id="qrcodeimg" src={imgSrc} alt="QR Code" className="img-fluid" />
                                    </div>
                                    <div className="text-center text-dark mt-2 mb-3">
                                        Tunjukkan QR-Code ini kepada Gerai yang ditunjuk<br />
                                        dan klaim hadiahnya!
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </>
                        : <NoAccount />
                }

            </div>
        </>
    );
}