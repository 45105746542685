import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { useLocation } from 'react-router-dom';
import { Dropdown, Alert } from 'react-bootstrap';
import avatarImg from '../images/avatar.png';
import { authService } from '../services';
import { voucherService } from '../services';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default function MerchantRedeemVoucher() {
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);
    const location = useLocation();
    const [accountVoucherId, setAccountVoucherId] = useState(null);
    const [accountVoucher, setAccountVoucher] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);
    const [showForm, setshowForm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);
    const [passwordType, setPasswordType] = useState("password");

    function logout() {
        authService.logout().then((res) => {
            context.setAccount(null);
            //navigate("/login?returnUrl=%2F");
        });
    }

    const handleShowForm = () => {
        setshowForm(true);
    };

    const handleHideForm = () => {
        setshowForm(false);
    };

    const handleClaimClick = (item) => {
        const elem = document.getElementById("redeemPin");

        if (elem && elem.value) {

            confirmAlert({
                overlayClassName: "my-alert",
                title: '',
                //message: 'Are you sure to exchange poin?',
                message: `Apakah Anda yakin untuk mengklaim Voucher ini?`,
                buttons: [
                    {
                        label: 'Ya',
                        className: 'btn-confirm-yes',
                        onClick: () => {
                            voucherService.claim(accountVoucherId, elem.value).then((res) => {
                                //console.log(res);

                                setShowSuccessMsg(true);
                                setMessageApi(res.message);

                                setshowForm(false);

                                voucherService.getOne(accountVoucherId).then(x => {
                                    setAccountVoucher(x)
                                });


                            }).catch(err => {
                                setShowError(true);
                                setErrorApi(err);
                            });

                        }
                    },
                    {
                        label: 'Tidak',
                        className: 'btn-confirm-no',
                        onClick: () => {
                            // do nothing
                        }
                    }
                ]
            });

        } else {
            // todo: err msg
        }

    };

    const handleClickEye = () => {
        if (passwordType === "password") {
            setPasswordType("text");
        } else {
            setPasswordType("password");
        }
    };

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        const avid = queryParams.get("accountVoucherId");
        setAccountVoucherId(avid);
        voucherService.getOne(avid).then(x => {
            //console.log(x);
            setAccountVoucher(x)
        });


    }, []);

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    return (
        <>
            
            <div className="pageContainer">

                {
                    (account) ?

                        <>
                            {
                                (accountVoucher) ?
                                    <>
                                        
                                        <div className="text-center mt-4">
                                            <div className="capInfoUserMerchant">
                                                <div>{account.name}</div>
                                                <div style={{ fontSize: "0.8rem" }}>
                                                    {accountVoucher.Voucher?.Merchant?.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center mt-4">
                                            <div className="divVoucherItem">
                                                <img src={accountVoucher.Voucher?.image} className="img-fluid" alt="" />
                                            </div>
                                            <div className="divVoucherItemName">
                                                {accountVoucher.Voucher?.name}
                                            </div>
                                        </div>

                                        <div className="divVoucherRvInformation">
                                            <div>
                                                <span style={{ width: "50px", display: "inline-block" }} className="voucherLabel">Kode</span>
                                                <span className="voucherLabel">: </span>
                                                {accountVoucher.Voucher?.code}
                                            </div>
                                            <div>
                                                <span style={{ width: "50px", display: "inline-block" }} className="voucherLabel">SN</span>
                                                <span className="voucherLabel">: </span>
                                                {accountVoucher.serialNumber}
                                            </div>
                                            <div>
                                                <span style={{ width: "50px", display: "inline-block" }} className="voucherLabel">Pemilik</span>
                                                <span className="voucherLabel">: </span>
                                                {accountVoucher.OwnerAccount?.name}
                                            </div>

                                            <div className="voucherDescription">
                                                {accountVoucher.Voucher?.description}
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div>URL Tidak valid.</div>
                                    </>
                            }





                            {
                                (accountVoucher) ?
                                    <>




                                        <div className="mt-4">

                                            <div style={{ maxWidth: "275px", margin: "auto" }}>
                                                {
                                                    (showSuccessMsg) ?
                                                        <>
                                                            <Alert variant="success" onClose={() => setShowSuccessMsg(false)} dismissible>
                                                                <Alert.Heading>Success!</Alert.Heading>
                                                                <p>
                                                                    {messageApi}
                                                                </p>
                                                            </Alert>
                                                        </>
                                                        :
                                                        ""
                                                }
                                            </div>

                                            {
                                                (showForm) ?
                                                    <>


                                                        <div className="row row-input-pin mb-2">

                                                            <label className="col-2 col-form-label text-right" style={{ fontWeight: "normal" }}>PIN</label>
                                                            <div className="col-6">
                                                                <div className="input-group">
                                                                    <input id="redeemPin" type={passwordType} className="form-control" ></input>
                                                                    <div className="input-group-text" onClick={handleClickEye} style={{ cursor: "pointer" }}>
                                                                        {
                                                                            (passwordType == "password") ?
                                                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                                                :
                                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <span className="btn btn-primary mr-1" onClick={handleClaimClick}>Klaim</span>
                                                            </div>

                                                        </div>

                                                        <div style={{ maxWidth: "275px", margin: "auto" }}>
                                                            {
                                                                (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                                                    <Alert.Heading>Error!</Alert.Heading>
                                                                    <p>
                                                                        {errorApi}
                                                                    </p>
                                                                </Alert> : <></>
                                                            }
                                                        </div>




                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (accountVoucher && accountVoucher.isUsed == 1) ?
                                                                <>
                                                                    <div className="text-center"><i>Voucher ini sudah digunakan</i></div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="text-center">
                                                                        <span className="btn btn-primary" onClick={handleShowForm}>Klaim Voucher Ini</span>
                                                                    </div>
                                                                </>
                                                        }

                                                    </>
                                            }


                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>URL Tidak valid.</div>
                                    </>
                            }

                        </>

                        : <NoAccount></NoAccount>
                }




            </div>
        </>
    );
}