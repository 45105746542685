import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/log`;

export const logService = {
    histories
};

async function histories(page, size) {
    return fetchWrapper.get(`${baseUrl}/histories?page=${page}&size=${size}`);
}
