import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, alertService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';

export default function FormRegister(props) {

    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [userEmail, setUserEmail] = useState(props.userEmail);

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [messageApi, setMessageApi] = useState(null);
    const [agreement, setAgreement] = useState(false);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        emailCode: Yup.string().required('Registration Code is required'),
        password: Yup.string().required("Password is required")
            .min(8, 'Minimum 8 characters')
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, 'Use alphanumeric only, at least 1 letter and 1 number'),
        passwordConfirmation: Yup.string().required('Password Confirmation is required')
            .oneOf([Yup.ref('password'), null], 'Password Confirmation must match'),
        username: Yup.string().required("Username is required")
            .min(5, 'Minium 5 characters')
            .matches(/^[0-9a-z]+$/, 'Username can only use lowercase alphanumeric'),
        gender: Yup.string().required('Gender is required')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };



    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ email, emailCode, password, username, gender }) {

        return authService.register(email, emailCode, password, username, gender)
            .then((res) => {
                // success
                setShowSuccessMsg(true);
                setMessageApi("Congratulation, your account is active");
            })
            .catch((err) => {
                setShowError(true);
                setErrorApi(err);
            });


    }

    const handleChange = (event) => {
        //console.log(event.target.checked);
        setAgreement(event.target.checked);
    }

    useEffect(() => {
        //console.log("EMAIL:", props.userEmail);
        setUserEmail(props.userEmail);
        setValue('email', userEmail);
        setValue('gender', 'M');
    }, [props.userEmail]);

    return (
        <>

            <div>


                {
                    (showSuccessMsg) ?
                        <>
                            <div className="mt-2">&nbsp;</div>
                            <Alert variant="success" dismissible>
                                <Alert.Heading>Success!</Alert.Heading>
                                <p>
                                    {messageApi}
                                </p>
                            </Alert>
                            <div className="mt-4">
                                <a href="/login" >Login</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="/" >Back to Home</a>
                            </div>
                        </>
                        : <>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="mt-3">&nbsp;</div>
                                <div className="form-group mb-2">

                                    <div><span style={{ fontWeight: "700" }}>Email: </span>  {userEmail}</div>
                                    <input name="email" type="hidden" {...register('email')} />
                                </div>

                                <div className="form-group mb-2">
                                    <input placeholder="Registration code" name="emailCode" type="text" {...register('emailCode')} className={`form-control ${errors.emailCode ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.emailCode?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    <input placeholder="Username" maxLength={20} name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.username?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    <input placeholder="Password" maxLength={20} name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>

                                <div className="form-group mb-2">
                                    <input placeholder="Password Confirmation" maxLength={20} name="passwordConfirmation" type="password" {...register('passwordConfirmation')} className={`form-control ${errors.passwordConfirmation ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.passwordConfirmation?.message}</div>
                                </div>

                                <div className="form-group mb-2">

                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" name="gender" value="M" {...register('gender')} className={`form-check-input ${errors.name ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">Male</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" name="gender" value="F" {...register('gender')} className={`form-check-input ${errors.name ? 'is-invalid' : ''}`} />
                                            <label className="form-check-label">Female</label>
                                        </div>
                                    </div>

                                    <div className="invalid-feedback">{errors.gender?.message}</div>
                                </div>

                                <div className="form-group mb-4">

                                <input onChange={handleChange} className="form-check-input" style={{ marginLeft: 0 }} type="checkbox" value="1" />

                                    <span style={{ fontSize: "12px", marginLeft: "20px" }}>By registering you agree to our</span>
                                    &nbsp;
                                    <a href="#" style={{ fontSize: "12px", color: "#14181D", fontWeight: "bold", textDecoration: "underline" }}>Terms of Use</a>


                                </div>

                                {
                                    (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                        <Alert.Heading>Error!</Alert.Heading>
                                        <p>
                                            {errorApi}
                                        </p>
                                    </Alert> : <></>
                                }


                                <button disabled={ (!agreement) || formState.isSubmitting} className="btn btn-primary" style={{ width: "300px" }}>
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Sign-Up
                                </button>

                            </form>
                        </>
                }

            </div>




        </>
    );
}