import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/poin`;

export const poinService = {
    histories
};

async function histories(page, size, poinType = null, inOutType = null) {
    let strSearch = "";
    if (poinType) {
        strSearch += `&poinType=${poinType}`;
    }

    if (inOutType) {
        strSearch += `&inOutType=${inOutType}`;
    }

    return fetchWrapper.get(`${baseUrl}/histories?page=${page}&size=${size}${strSearch}`);
}
