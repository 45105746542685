import { useState, useEffect, useRef } from 'react';
import banner1 from '../images/banner1.png';
import tround from '../images/tround.png';

import imgFrame9 from '../images/frame9.png';
import imgNft from '../images/nft.png';
import imgArrowRight from '../images/arrow-right.png';

import '../nft-buildings-v3.css';

export default function NftBuildings() {


    return (
        <>
            <div className="topBigImage" style={{ height: "325px" }}>
                <div className="topBigImageOverlay">
                    <p className="homeCenterBigText" style={{paddingTop: "100px"}}>
                        Explore our collection
                    </p>
                </div>

            </div>

            <div className="pageContainer">
                
                <div style={{marginTop: "50px"}}></div>

                <div className="row">
                    <div id="divNB_logo">
                        <div id="building_divTround">
                            <img src={tround} alt="Techpolitan" className="img-fluid" />
                        </div>
                    </div>

                    <div id="divNB_content">
                        <div className="globalSectionBigText">Land & Estate</div>

                        <p>We are a Metaverse builder – a virtual world to embrace mixed-reality future – powered by the grit & creativity of our team in Asia.</p>

                    </div>

                    <div id="divNB_total">
                        <span style={{ fontSize: "18px" }}>Total Assets</span>
                        &nbsp; &nbsp;
                        <span style={{ fontSize: "18px", fontWeight: 700 }}>60</span>
                    </div>

                    


                </div>

                <div style={{ height: "30px" }}>&nbsp;</div>


                <div className="row">
                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title"><a href="/nft-building/1">Meta Estates 1A</a></div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn btn-primary" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div style={{ height: "40px" }}>&nbsp;</div>

                <div style={{ textAlign: "center" }}>
                    <button className="btn bg-white" style={{ width: "150px" }}>Load More</button>
                </div>



            </div>
        </>
    );
}