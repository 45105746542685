import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/auth`;
const userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userTpWeb')));

export const authService = {
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value },
    login,
    me,
    logout,
    preregister,
    register, 
    lostPassword, 
    resetPassword, 
    setUserSubject, 
    contactUs
};

function setUserSubject(user) {
    userSubject.next(user);
    localStorage.setItem('userTpWeb', JSON.stringify(user));
}

async function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/login`, { email, password })
        .then(user => {
            // publish user to subscribers and store in local storage to stay logged in between page refreshes
            //userSubject.next(user);
            //localStorage.setItem('userTpWeb', JSON.stringify(user));
            setUserSubject(user);

            return user;
        });
}

async function me() {
    return fetchWrapper.get(`${baseUrl}/me`);
}

async function logout() {
    
    // remove user from local storage, publish null to user subscribers and redirect to login page
    localStorage.removeItem('userTpWeb');
    userSubject.next(null);
    return true;
    //navigate("/");
}

async function preregister(email, referralCode) {
    return fetchWrapper.post(`${baseUrl}/pre-register`, { email, referralCode });
}

async function register(email, emailCode, password, username, gender) {
    return fetchWrapper.post(`${baseUrl}/register`, { email, emailCode, password, username, gender });
}

async function lostPassword(email) {
    return fetchWrapper.post(`${baseUrl}/lost-password`, { email });
}

async function resetPassword(email, resetCode, newPassword) {
    return fetchWrapper.post(`${baseUrl}/reset-password`, { email, resetCode, newPassword });
}

async function contactUs(name, email, message, contact_form_token) {
    return fetchWrapper.post(`${baseUrl}/contact-us`, { name, email, message, contact_form_token });
}