import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/account`;

export const accountService = {
    updateSolAddress, 
    updateEthAddress,
    updateProfile, 
    updatePassword
};

async function updateSolAddress(solAddress) {
    return fetchWrapper.put(`${baseUrl}/update-sol-address`, { solAddress });
}

async function updateEthAddress(ethAddress) {
    return fetchWrapper.put(`${baseUrl}/update-eth-address`, { ethAddress });
}

async function updateProfile(username, name, gender, birthDate, city, profession) {
    return fetchWrapper.put(`${baseUrl}/update-profile`, { username, name, gender, birthDate, city, profession });
}

async function updatePassword(old_password, new_password) {
    return fetchWrapper.put(`${baseUrl}/update-password`, { old_password, new_password });
}
