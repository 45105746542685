import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { poinService } from '../services';
import imgMoney from '../images/money.png';
import { Tabs, Tab } from 'react-bootstrap';
import useWindowDimensions from '../components/useWindowDimensions ';

export default function PoinInWeb() {

    const { height, width } = useWindowDimensions();
    const [panelHeight, setPanelHeight] = useState(210);
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(10000);
    const [dataItems, setDataItems] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);
    const [curPageOut, setCurPageOut] = useState(0);
    const [pageCountOut, setPageCountOut] = useState(0);
    const [dataItemsOut, setDataItemsOut] = useState(null);

    const getIndex = (page, size) => {
        poinService.histories(page, size, null, "in").then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const getIndexOut = (page, size) => {
        poinService.histories(page, size, null, "out").then(x => {
            setDataItemsOut(x.result);
            setCurPageOut(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCountOut(x.meta.pageCount);
        });
    }


    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    function strTimeSince(dtime) {
        return timeSince(new Date(dtime)) + " ago";
    }

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);
        getIndexOut(curPage, perPage);
        setPanelHeight(height - 130);
    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <div className="row mb-2">
                                <div className="col">
                                    <span>Poin Saat Ini</span>
                                </div>
                                <div className="col text-right">
                                    <span><img src={imgMoney} alt="Money" /> {account.totalPoin}</span>
                                </div>
                            </div>


                            <Tabs
                                defaultActiveKey="inpoin"
                                id="poin-tab"

                            >
                                <Tab eventKey="inpoin" title="Poin Masuk">
                                    {
                                        (dataItems) ? <>
                                            <div style={{ height: `${panelHeight}px`, overflowY: "auto" }}>
                                                {
                                                    dataItems.map((item, i) => {
                                                        ++i;

                                                        return (
                                                            <div key={item.id} className="divGrayCorner">
                                                                <div className="profileSubTitle">
                                                                    <span>{item.title}</span>
                                                                    &nbsp; &nbsp;
                                                                    {
                                                                        (item.poinValue > 0) ?
                                                                            <span className="badge bg-success">
                                                                                +{item.poinValue}
                                                                            </span> :
                                                                            <span className="badge bg-danger">
                                                                                {item.poinValue}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        /**
                                                                         * <span className="badge bg-yellow ml-2">
                                                                        {item.totalPoin}
                                                                    </span>
                                                                         */
                                                                    }

                                                                    <span style={{ fontSize: "0.8rem", float: "right", color: "#CCCCCC" }} className="date timeago" title={item.createdAt}>
                                                                        {strTimeSince(item.createdAt)}
                                                                    </span>
                                                                </div>
                                                                <div className="">

                                                                    <div style={{ fontSize: "0.9rem", paddingTop: "5px", paddingLeft: "10px", color: "#CCCCCC" }}>{item.description}</div>

                                                                </div>


                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </>
                                            : <>
                                                <div>No data found.</div>
                                            </>
                                    }
                                </Tab>
                                <Tab eventKey="outpoin" title="Poin Keluar">
                                    {
                                        (dataItemsOut) ? <>
                                            <div style={{ height: `${panelHeight}px`, overflowY: "auto" }}>
                                                {
                                                    dataItemsOut.map((item, i) => {
                                                        ++i;

                                                        return (
                                                            <div key={item.id} className="divGrayCorner">
                                                                <div className="profileSubTitle">
                                                                    <span>{item.title}</span>
                                                                    &nbsp; &nbsp;
                                                                    {
                                                                        (item.poinValue > 0) ?
                                                                            <span className="badge bg-success">
                                                                                +{item.poinValue}
                                                                            </span> :
                                                                            <span className="badge bg-danger">
                                                                                {item.poinValue}
                                                                            </span>
                                                                    }
                                                                    {
                                                                        /**
                                                                         * <span className="badge bg-yellow ml-2">
                                                                        {item.totalPoin}
                                                                    </span>
                                                                         */
                                                                    }
                                                                    <span style={{ fontSize: "0.8rem", float: "right", color: "#CCCCCC" }} className="date timeago" title={item.createdAt}>
                                                                        {strTimeSince(item.createdAt)}
                                                                    </span>
                                                                </div>
                                                                <div className="">

                                                                    <div style={{ fontSize: "0.9rem", paddingTop: "5px", paddingLeft: "10px", color: "#CCCCCC" }}>{item.description}</div>

                                                                </div>


                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </>
                                            : <>
                                                <div>No data found.</div>
                                            </>
                                    }
                                </Tab>
                            </Tabs>

                        </>
                        : <NoAccount />
                }
            </div>
        </>
    );
}