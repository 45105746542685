import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import './globals-v3.css';
import './App.css';
import './i18n'
import { Outlet } from "react-router-dom";
import { authService } from './services';
import PageHeader from './components/page-header';
import PageFooter from './components/page-footer';

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  return library
}

function App() {
  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState(null);
  const [afterCheckUser, setAfterCheckUser] = useState(false);

  const [walletAccount, setWalletAccount] = useState(null);
  const [walletChainId, setWalletChainId] = useState(null);
  const [walletActive, setWalletActive] = useState(null);
  const [walletLibrary, setWalletLibrary] = useState(null);
  const location = useLocation();

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const noheaderfooter = queryParams.get("noheaderfooter");
    const lang = queryParams.get("lang");

    if (lang != null && lang !== '' && lang !== 'none') {
      localStorage.setItem('lang', lang);
    } else if (lang === 'none') {
      localStorage.removeItem('lang');
    }

    //console.log(token);
    if (token) {
      authService.setUserSubject({ token: token });
    }

    if (noheaderfooter === '1') {
      localStorage.setItem('noheaderfooter', '1');
    } else if (noheaderfooter === '0') {
      localStorage.removeItem('noheaderfooter');
    }

    if (localStorage.getItem("userTpWeb") !== null) {
      authService.me()
        .then((res) => {
          setAccount(res);
          setAfterCheckUser(true);
        })
        .catch((err) => {
          setAccount(null);
          setAfterCheckUser(true);
        });
    } else {
      setAccount(null);
      setAfterCheckUser(true);
    }

    //console.log("noheaderfooter:", noheaderfooter);

    if (localStorage.getItem("noheaderfooter") === '1') {
      document.body.classList.add('noheaderfooter');
    } else {
      document.body.classList.remove('noheaderfooter');
    }

    //console.log("noHeaderFooter:", noHeaderFooter);

    const thelang = localStorage.getItem("lang");
    if (thelang != null && thelang !== '') {
      i18n.changeLanguage(thelang);
    } else {
      i18n.changeLanguage("en");
    }

    
    if (location.pathname === '/login' 
        || location.pathname === '/register'
        || location.pathname ===  '/lost-password') {

      document.body.classList.add('logintheme');
    } else {
      document.body.classList.remove('logintheme');
    }



  }, []);


  return (
    <>


      <Web3ReactProvider getLibrary={getLibrary}>


        <PageHeader
          account={account} setAccount={setAccount}
          afterCheckUser={afterCheckUser}
          walletAccount={walletAccount} setWalletAccount={setWalletAccount}
          walletChainId={walletChainId} setWalletChainId={setWalletChainId}
          walletActive={walletActive} setWalletActive={setWalletActive}
          walletLibrary={walletLibrary} setWalletLibrary={setWalletLibrary}

        ></PageHeader>

        <Outlet context={{
          account: account,
          setAccount: setAccount,
          walletAccount: walletAccount,
          setWalletAccount: setWalletAccount,
          walletChainId: walletChainId,
          setWalletChainId: setWalletChainId,
          walletActive: walletActive,
          setWalletActive: setWalletActive,
          walletLibrary: walletLibrary,
          setWalletLibrary: setWalletLibrary

        }} />

        <PageFooter ></PageFooter>


      </Web3ReactProvider>



    </>
  );
}

export default App;
