import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { authService } from '../services';
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import avatarImg from '../images/avatar-black.png';
import ConnectWallet from './connect-wallet';
import logoText from '../images/logo_text.svg';
import wheelWhite from '../images/Vector.svg';

import imenuProfileB from '../images/ic-black/ic_profile.svg';
import imenuPointB from '../images/ic-black/ic_point_history.svg';
import imenuVoucherB from '../images/ic-black/ic_myvoucher.svg';
import imenuExchangeB from '../images/ic-black/ic_exchange_point.svg';
import imenuConvertB from '../images/ic-black/ic_convert_point.svg';
import imenuLogoutB from '../images/ic-black/ic_logout.svg';
import imenuPasswdB from '../images/ic-black/ic_password.svg';

import imenuProfileW from '../images/ic-white/ic_profile.svg';
import imenuPointW from '../images/ic-white/ic_point_history.svg';
import imenuVoucherW from '../images/ic-white/ic_myvoucher.svg';
import imenuExchangeW from '../images/ic-white/ic_exchange_point.svg';
import imenuConvertW from '../images/ic-white/ic_convert_point.svg';
import imenuLogoutW from '../images/ic-white/ic_logout.svg';
import imenuPasswdW from '../images/ic-white/ic_password.svg';


export default function PageHeader(props) {
    const location = useLocation();
    const [account, setAccount] = useState(props.account);
    const [walletAccount, setWalletAccount] = useState(props.walletAccount);
    const [walletChainId, setWalletChainId] = useState(props.walletChainId);
    const [walletActive, setWalletActive] = useState(props.walletActive);
    const [walletLibrary, setWalletLibrary] = useState(props.walletLibrary);
    const navigate = useNavigate();
    const [showUserInfoOrLogin, setShowUserInfoOrLogin] = useState(props.afterCheckUser);


    function logout() {
        authService.logout().then((res) => {
            props.setAccount(null);
            navigate("/");
        });
    }

    useEffect(() => {
        setAccount(props.account);
        setShowUserInfoOrLogin(props.afterCheckUser);
    }, [props.account, props.afterCheckUser]);

    useEffect(() => {
        props.setWalletAccount(walletAccount);
        props.setWalletChainId(walletChainId);
        props.setWalletActive(walletActive);
        props.setWalletLibrary(walletLibrary);
    }, [walletAccount, walletChainId, walletActive, walletLibrary]);

    useEffect(() => {

    }, []);

    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top" id="divHeader">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={wheelWhite} alt="Wh3el" className="img-fluid img-wheel-logo" />
                </a>

                <div className="d-flex">
                    {
                        (showUserInfoOrLogin) ?
                            <div>
                                {
                                    (account) ?
                                        <div className="navbar-nav ms-auto">

                                            <Dropdown align={"end"}>
                                                <Dropdown.Toggle id="dropdown-basic-user" style={{ marginRight: "0", textTransform: "none" }}>

                                                    <div className="divAvatarImg">
                                                        <img alt={account.name} src={avatarImg} className="rounded" />
                                                    </div>

                                                    <div className="divAvatarText">
                                                        {account.name}
                                                    </div>


                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>

                                                    <ConnectWallet
                                                        walletAccount={walletAccount} setWalletAccount={setWalletAccount}
                                                        walletChainId={walletChainId} setWalletChainId={setWalletChainId}
                                                        walletActive={walletActive} setWalletActive={setWalletActive}
                                                        walletLibrary={walletLibrary} setWalletLibrary={setWalletLibrary}
                                                    />

                                                    <Dropdown.Divider />

                                                    <Dropdown.Item href="/view-profile" className="dropdown-item-first">
                                                        <img alt="Profile" src={imenuProfileB} className="ic_black" />
                                                        <img alt="Profile" src={imenuProfileW} className="ic_white" />
                                                        Profile
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="/poin-in">
                                                        <img alt="Profile" src={imenuPointB} className="ic_black" />
                                                        <img alt="Profile" src={imenuPointW} className="ic_white" />
                                                        Point History
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="/voucher-unused">
                                                        <img alt="Profile" src={imenuVoucherB} className="ic_black" />
                                                        <img alt="Profile" src={imenuVoucherW} className="ic_white" />
                                                        My Voucher
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="/poin-exchange">
                                                        <img alt="Profile" src={imenuExchangeB} className="ic_black" />
                                                        <img alt="Profile" src={imenuExchangeW} className="ic_white" />
                                                        Exchange Point
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="#">
                                                        <img alt="Profile" src={imenuConvertB} className="ic_black" />
                                                        <img alt="Profile" src={imenuConvertW} className="ic_white" />
                                                        Convert Point
                                                    </Dropdown.Item>

                                                    <Dropdown.Divider />

                                                    <Dropdown.Item href="/change-password">
                                                        <img alt="Profile" src={imenuPasswdB} className="ic_black" />
                                                        <img alt="Profile" src={imenuPasswdW} className="ic_white" />
                                                        Change Password..
                                                    </Dropdown.Item>

                                                    <Dropdown.Item onClick={logout} className="dropdown-item-last">
                                                        <img alt="Profile" src={imenuLogoutB} className="ic_black" />
                                                        <img alt="Profile" src={imenuLogoutW} className="ic_white" />
                                                        Logout
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>


                                        </div>
                                        :
                                        <>
                                            
                                        </>
                                }
                            </div>
                            :
                            ""
                    }

                </div>
            </div>
        </nav>
    );
}