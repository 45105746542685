import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';

export default function Profile() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    return (
        <>

            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <h1>Profile</h1>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "160px" }} className="tbl-td-label">Name</td>
                                        <td>{account.name}
                                            <a title="Edit Profile.." href="edit-profile" className="btn btn-primary btn-sm ml-2">
                                                <i className="fa fa-edit"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tbl-td-label">Email</td>
                                        <td>{account.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="tbl-td-label">Wallet Address</td>
                                        <td>{account.solAddress}

                                            <a title="Edit Wallet Address.." href="edit-wallet" className="btn btn-primary btn-sm ml-2">
                                                <i className="fa fa-edit"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tbl-td-label">Total Point</td>
                                        <td>{account.totalPoin}

                                            <a href="/poin-histories" className="btn btn-primary btn-sm ml-4">
                                                <i className="fa fa-search"></i>&nbsp;
                                                Histories
                                            </a>

                                            <a href="/poin-exchange" className="btn btn-primary btn-sm ml-4">
                                                <i className="fa fa-exchange-alt"></i>&nbsp;
                                                Exchange..
                                            </a>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        : <NoAccount />
                }
            </div>



        </>
    );
}