import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { accountNftService } from '../services';
import ReactPaginate from 'react-paginate';
import { Alert, Modal } from 'react-bootstrap';

export default function MyNft() {

    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(8);
    const [dataItems, setDataItems] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);

    const [activeItem, setActiveItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const getIndex = (page, size) => {
        accountNftService.myNfts(page, size).then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const handlePageClick = (p) => {
        const page = p.selected;
        setCurPage(page);
        getIndex(page, perPage);
    };

    const handleBoxAccountNftClick = (item) => {
        setShowAlert(false);
        setAlertMessage("");
        const collection = document.getElementsByClassName("divAccountNftBox");
        Array.prototype.forEach.call(collection, function (el) {
            el.classList.remove("selected");
        });

        setActiveItem(item);
        //console.log("accountNft-" + item.id);
        let elem = document.getElementById("accountNft-" + item.id);
        if (elem) {
            elem.classList.add("selected");
        }
    };

    



    useEffect(() => {
        setAccount(context.account);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);

        //console.log(process.env.REACT_APP_BASE_WEB);
    }, []);

    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                {
                    (account) ?
                        <>
                            <div className="myPageTitle">My NFT's</div>
                            {
                                (dataItems) ? <>

                                    <div className="row">
                                        <div className="col">
                                            {
                                                dataItems.map((item, i) => {
                                                    ++i;

                                                    return (
                                                        <div key={item.id} className="divAccountNftBox" id={`accountNft-${item.id}`} onClick={() => handleBoxAccountNftClick(item)}>
                                                            <div>IMG</div>
                                                            <div>{item.nftSymbol}</div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="clearfix"></div>

                                            <ReactPaginate
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={perPage}
                                                pageCount={pageCount}
                                                renderOnZeroPageCount={null}

                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"

                                            />
                                        </div>

                                        <div className="col">
                                            {
                                                (activeItem) ?
                                                    <>
                                                        <div>{activeItem.nftName}</div>
                                                        <div><span className="accountNftLabel">Mint:</span> {activeItem.mintToken}</div>
                                                        


                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            (showAlert) ? <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                                                                <Alert.Heading></Alert.Heading>
                                                                <p>{alertMessage}</p>
                                                            </Alert> : ""
                                                        }

                                                        <div>
                                                            <i>Click item on the left to display detail information</i>
                                                        </div>

                                                    </>
                                            }

                                        </div>

                                    </div>






                                </>
                                    : <>
                                        <div>No data found.</div>
                                    </>
                            }

                            

                        </>
                        : <NoAccount />
                }

            </div>
        </>
    );
}