import { useState, useEffect } from 'react';

import tImg from '../images/t.png';
import tBlack from '../images/t_black.png';
import iconDiscord from '../images/icon-discord.png';
import iconTelegram from '../images/icon-telegram.png';
import iconTwitter from '../images/icon-twitter.png';
import iconFacebook from '../images/icon-facebook.png';
import logoText from '../images/logo_text.svg';
import wheelWhite from '../images/logo-wh3el.svg';
import imgWgs from '../images/wgs.svg';
import terms_conditions_wc from '../legals/terms_conditions_wc.pdf'
import privacy_policy_wc from '../legals/privacy_policy_wc.pdf'
import disclaimer_wc from '../legals/disclaimer_wc.pdf'
import terms_conditions from '../legals/terms_conditions.pdf'
import privacy_policy from '../legals/privacy_policy.pdf'
import disclaimer from '../legals/disclaimer.pdf'


export default function PageFooter(props) {
	const thisYear = new Date()?.getFullYear()

    return (
		<div className='footer-container'>
            <div className="px-3 px-md-5 py-3 border-top border-secondary d-flex flex-column flex-row justify-content-md-between border-top-gradient">
				<div className='d-flex justify-content-center align-items-center flex-column flex-md-row border-bottom-gradient pb-3'>
					<a className='navbar-brand d-inline-block text-white px-5' href={terms_conditions} target="_blank">
						Terms & Conditions
					</a>
					<a className='navbar-brand d-inline-block text-white border-mx px-5' href={privacy_policy} target="_blank">
						Privacy Policy
					</a>
					<a className='navbar-brand d-inline-block text-white px-5' href={disclaimer} target="_blank">
						Disclaimer
					</a>
				</div>
                <div className='d-flex flex-column flex-md-row justify-content-between align-items-center pt-3'>
                    <span className="text-white my-auto mx-auto mx-md-2">
                        {`© ${thisYear} WH3EL. All rights reserved`}
                    </span>
                    <a className='navbar-brand d-inline-block text-white mx-auto mx-md-2' href='https://www.wh3el.io/' target="_blank"> 
                        Wh3el is the Web3 initiative of
                        <img
                            className='margin-footer-icon ml-2'
                            alt='wgs'
                            src={imgWgs}
                            width={100}
                            height={40}
                        />
                    </a>
                </div>
			</div>
            <a href={privacy_policy_wc} className='d-none'></a>
            <a href={terms_conditions_wc} className='d-none'></a>
            <a href={disclaimer_wc} className='d-none'></a>
		</div>
    );
}