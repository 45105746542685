import { useState, useEffect } from 'react';

const NftBox3 = (props) => {
    const [loadingProcess, setLoadingProcess] = useState(false);
    
    async function MintOne() {
        setLoadingProcess(true);
        await props.onMint(props.cid, props.strNum);
        setLoadingProcess(false);
        console.log("... done");
    }

    useEffect(() => {

    }, [props]);

    return (<>

        <div className="col-3" key={props.strNum} >
            <div style={
                {
                    border: "1px solid #FFF",
                    borderRadius: "10px",
                    padding: "10px",
                    marginBottom: "15px",
                    textAlign: "center",
                    background: "#383838"
                }
            }      >


                <div>#{props.strNum}</div>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <img src={props.imgSrc} style={{ width: "100px" }} />
                </div>
                <div>
                    {
                        (props.price) ? <span>{props.price}</span> : <span>?</span>
                    }

                    &nbsp;<span>ETH</span>

                </div>
                {
                    (props.walletActive) ? <>

                        {

                            (!props.isMinted) ?
                                <>
                                    <div>

                                        {
                                            (loadingProcess) ?
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                : <>
                                                    <button style={{ width: "100px" }} onClick={() => MintOne()} className="btn btn-warning">Mint</button>
                                                </>
                                        }

                                    </div>
                                </>
                                :
                                <>
                                    <button style={{ width: "100px" }} disabled className="btn btn-secondary">Minted</button>
                                </>



                        }


                    </> : <>
                        <div>
                            <button style={{ width: "100px" }} disabled className="btn btn-secondary">Mint</button>
                        </div>
                    </>
                }


            </div>


        </div>


    </>);
}


export default NftBox3;