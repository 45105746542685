import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export { NftAddress };

NftAddress.propTypes = {
    text: PropTypes.any,
    itemId: PropTypes.any, // ID pembeda item
    tagName: PropTypes.any, // Tag pembeda elemen, bebas, yg penting tidak sama dalam 1 page
    varian: PropTypes.any // "lg", "md"
};

function NftAddress({ children, text, itemId, tagName, varian, ...props }) {

    const [width, setWidth] = useState("150px");

    const onCopied = (id) => {
        const el = document.getElementById(`CopyToClipboardAlert${tagName}-${id}`);
        el.style.visibility = "visible";
        setTimeout(() => {
            el.style.visibility = "hidden";
        }, 1000);
    };

    useEffect(() => {
        if (varian == "lg") {
            setWidth("390px");
        } else {
            setWidth("150px")
        }
    }, []);

    return (
        <>

            {
                (text) ? <><div style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: width,
                    float: "left",
                    fontFamily: "monospace",
                    fontSize: "16px"

                }}>
                    {text}
                </div>
                    &nbsp;
                    <CopyToClipboard text={text}
                        onCopy={() => onCopied(itemId)}>
                        <i className="far fa-copy" style={{ cursor: "pointer" }} title="Copy text..">&nbsp;</i>
                    </CopyToClipboard>
                    <div id={`CopyToClipboardAlert${tagName}-${itemId}`} className={`CopyToClipboardAlert btn btn-sm btn-light mx-2`}>
                        Copied.
                    </div></> : <></>
            }

        </>

    );
}