import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { authService, alertService } from '../services';
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { useOutletContext, useLocation } from "react-router-dom";
import logoText from '../images/techpol_metaverse.svg';
import wheelWhite from '../images/wheel-icon.svg';

import '../login-v2.css';

export default function Login() {
    const location = useLocation();
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);
    const [showError, setShowError] = useState(false);
    const [errorApi, setErrorApi] = useState(null);
    const [agreement, setAgreement] = useState(false);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Account is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();

    function onSubmit({ email, password }) {

        return authService.login(email, password)
            .then(() => {
                // get return url from query parameters or default to '/'
                //const returnUrl = router.query.returnUrl || '/';
                //router.push(returnUrl);
                setShowError(false);

                authService.me()
                    .then((res) => {
                        context.setAccount(res);
                    })
                    .catch((err) => {
                        context.setAccount(null);
                    });

                const queryParams = new URLSearchParams(location.search);
                const returnUrl = queryParams.get("returnUrl");

                if (returnUrl) {
                    window.location.href = returnUrl;
                } else {
                    window.location.href = "/";
                }

                //navigate(returnUrl);
            })
            .catch((err) => {
                setShowError(true);
                setErrorApi(err);
            });

    }

    const handleChange = (event) => {
        //console.log(event.target.checked);
        setAgreement(event.target.checked);
    }

    useEffect(() => {
        if (context.account) {
            navigate("/view-profile");
        }

        setAccount(context.account);
    }, [context.account]);

    return (
        <>

            <div className="login-page-container">
                <div className="login-page-bg">
                    <div className="pageContainer fw100">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">

                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="login-vertical-center">
                                    <div style={{ margin: "auto", width: "300px" }}>
                                        <div style={{textAlign: "center"}}>
                                            <a href="/"><img src={wheelWhite} alt="Wheel" className="img-fluid" width={250}/></a>
                                        </div>


                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mt-3">&nbsp;</div>
                                            <div className="form-group mb-2">

                                                <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="Enter e-mail or username" />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </div>
                                            <div className="form-group mb-2">

                                                <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Enter your password" />
                                                <div className="invalid-feedback">{errors.password?.message}</div>
                                            </div>

                                            <div className="form-group mb-4">

                                                <input onChange={handleChange} className="form-check-input" style={{ marginLeft: 0 }} type="checkbox" value="1" />

                                                <span style={{ fontSize: "12px", marginLeft: "20px" }}>By registering you agree to our</span>
                                                &nbsp;
                                                <a href="#" style={{ fontSize: "12px", color: "#14181D", fontWeight: "bold", textDecoration: "underline" }}>Terms of Use</a>


                                            </div>


                                            {
                                                (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                                    <Alert.Heading>Error!</Alert.Heading>
                                                    <p>
                                                        {errorApi}
                                                    </p>
                                                </Alert> : <></>
                                            }



                                            <button disabled={ (!agreement) || formState.isSubmitting} className="btn btn-primary" style={{ width: "300px" }}>
                                                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                Login
                                            </button>

                                            {
                                                /**
                                                 * <div className="mt-4">
                                                <a href="/register" className="btn btn-outline-dark" style={{width: "140px", marginRight: "18px"}}>Registration</a>
                                                <a href="/lost-password" className="btn btn-outline-dark"  style={{width: "140px"}}>Lost Password?</a>

                                            </div>
                                                 */
                                            }

                                            <div className="login_linkreg" style={{ marginTop: "100px", textAlign: "center" }}>
                                                <div style={{ fontSize: "12px" }}>
                                                    <span>Not a member?</span>&nbsp;
                                                    <a href="/register">Sign up now</a>
                                                </div>
                                                <div style={{ fontSize: "12px" }}>

                                                    <a href="/lost-password">Lost Password?</a>
                                                </div>
                                            </div>



                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>






        </>
    );
}