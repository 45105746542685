import { useState, useEffect, useRef } from 'react';
import banner1 from '../images/banner1.png';
import tround from '../images/tround.png';

import imgFrame9 from '../images/frame9.png';
import imgNft from '../images/nft.png';
import imgArrowRight from '../images/arrow-right.png';
import FormContact from '../components/form-contact';

export default function ContactUs() {


    return (
        <>
            <div className="topBigImage" style={{ height: "325px" }}>
                <div className="topBigImageOverlay">
                    <p className="homeCenterBigText" style={{paddingTop: "100px"}}>
                        Contact Us
                    </p>
                </div>

            </div>

            <div style={{ marginBottom: "80px" }}></div>

            <div className="pageContainer">
                <div className="row">
                    <div className="col">
                        
                        <div className="globalTitleSection">GET IN TOUCH</div>

                        <div className="globalSectionBigText">We'd Love To Hear From You</div>

                        <p style={{paddingTop: "20px"}}>Reach to us and let’s discuss about your idea!</p>

                    </div>
                    <div className="col">
                        <div className="globalFormContainer">
                            <FormContact />
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}