import { useState, useEffect, useRef } from 'react';
import banner1 from '../images/banner1.png';
import tround from '../images/tround.png';
import iconDiscord from '../images/icon-discord.png';
import iconTelegram from '../images/icon-telegram.png';
import iconTwitter from '../images/icon-twitter.png';
import iconFacebook from '../images/icon-facebook.png';
import imgFrame9 from '../images/frame9.png';
import imgNft from '../images/nft.png';
import imgArrowRight from '../images/arrow-right.png';
import vrglass from '../images/launhpad-img.png';
import vrdeveloper from '../images/vr-developers.jpg';
import bulletblue from '../images/bullet-blue.svg';
import c3_1 from '../images/c3_1.jpg';
import c3_2 from '../images/c3_2.jpg';
import c3_3 from '../images/c3_3.jpg';
import p1 from '../images/aji-60.png';
import p2 from '../images/stef-60.png';
import tato from '../images/tato.jpg';
import vrAi from '../images/wheel-city-min.png'
import videoUrl from '../videos/bg.mp4'
import apesImage1 from '../images/apes1.png'
import apesImage2 from '../images/apes2.png'
import apesImage3 from '../images/apes3.png'
import apesImage4 from '../images/apes4.png'
import roadmapImg1 from '../images/RM1.png'
import roadmapImg2 from '../images/RM2.png'
import roadmapImg3 from '../images/RM3.png'
import roadmapImg4 from '../images/RM4.png'
import roadmapImg5 from '../images/RM5.png'
import roadmapImg6 from '../images/RM6.png'
import roadmapImg1Mobile from '../images/RM1Mb.png'
import roadmapImg2Mobile from '../images/RM2Mb.png'
import roadmapImg3Mobile from '../images/RM3Mb.png'
import lineMobile from '../images/lineMobile.svg'
import roadmapImg from '../images/roadmap.png'
import JoinUsBG from '../images/join-us-bg.png'
import imgDiscord from '../images/ic-discord.svg'
import imgTwitter from '../images/fa-twitter.svg'
import metaverseImg from '../images/wheelcity.png'
import ourVisionImg from '../images/mascot_big.png'
import scrollImg from '../images/ic-scroll.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'

import '../home-v4.css';
import { from } from 'rxjs';

export default function Home(props) {
	const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [account, setAccount] = useState(props.account);

    console.log(window.innerWidth)
    
	useEffect(() => {
		const handleWindowResize = () => {
			let w = window.innerWidth
			setInnerWidth(w)
		}

		handleWindowResize()

		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease', // Animation easing
        });
    }, []);

    let styleBigImage = innerWidth > 1080 ? {height: '100%'} : {height: '400px'};
    let styleBigGlowText = innerWidth > 1080 ? {paddingTop: '300px'} : {paddingTop: '100px'};
    let styleScrollImg = innerWidth > 1080 ? {
        marginTop: '140px', 
        marginBottom: '75px',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0, 
        position: 'absolute', 
        zIndex: 1
    } : {
        marginTop: '150px', 
        marginBottom: '25px', 
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0, 
        position: 'absolute', 
        zIndex: 1
    };
    let styleApesImg = innerWidth > 1080 ? {marginTop: '-80px'} : {marginTop: '-50px'}

    return (
        <>
            {
                innerWidth > 768 ?
                    <video id='background-video' autoPlay loop muted poster='' style={styleBigImage}>
                        <source src={videoUrl} type='video/mp4'/>
                    </video>
                : null
            }
            <div className="topBigImage" style={styleBigImage}>
                <div className="topBigImageOverlay">
                    <p className="homeCenterBigText glow-text text-white" style={styleBigGlowText} data-aos="fade-up">
                        <a className=''>Blockchain For</a> Businesses
                    </p>
                    <p className="homeCenterContentText glow-text" data-aos="fade-up">
                        We are a Web3 startup specializing in technology solutions. Connecting people with blockchain technologies.
                    </p>
                    {
                        innerWidth > 1080 ?
                            <a href="#launchpad-section" data-aos="fade-up">
                                <img src={scrollImg} style={styleScrollImg} />
                            </a>
                        : null
                    }
                </div>
            </div>

            <div className="homeBigLines pt-5" id="launchpad-section">
                <div className="homeBigLinesOverlay pt-3">
                    <div className="homePageContainer">
                        <div className="row">
                            <div className="col-md-6 mb-3 p-2">
                                <div className="row">
                                    <div className="col-6" data-aos='flip-left'>
                                        <img style={{borderRadius: "5px"}} src={apesImage1} className="img-fluid" />
                                    </div>
                                    <div className="col-6" data-aos='flip-left'>
                                        <img style={{borderRadius: "5px"}} src={apesImage2} className="img-fluid" />
                                    </div>
                                    <div className="col-6" style={styleApesImg} data-aos='flip-left'>
                                        <img style={{borderRadius: "5px"}} src={apesImage3} className="img-fluid" />                                        
                                    </div>
                                    <div className="col-6" style={styleApesImg} data-aos='flip-left'>
                                        <img style={{borderRadius: "5px"}} src={apesImage4} className="img-fluid" />

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 m-auto">
                                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                    <p className='emveep-nft-box text-white' data-aos="fade-up">
                                        // Launchpad Platform
                                    </p>
                                    <div className="homeTitleSection" data-aos="fade-up">NFT LAUNCHPAD</div>
                                    <p data-aos="fade-up">
                                    Wh3el owns its own NFT launchpad, which aims to provide a secure platform for launching NFT collections. We offer several benefits to holders of our inaugural NFT collection. Get exciting benefits for the holders of our first NFT collection.
                                    </p>
                                    <div className="mt-4" data-aos="fade-up">
                                        <a style={{ width: "200px" }} href="https://nft.wh3el.io/" className="btn btn-primary btn-gradient-purple">View Collection</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>

            <div className="homeMetaverse">
                <div className="homePageContainer">
                    <div className="card card-bg-purple p-0 m-3" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-6">
                                <div className='p-5'>
                                    <p className="emveep-nft-box" data-aos="fade-up">// Metaverse</p>
                                    <p className="text-white" data-aos="fade-up">WH3EL CITY</p>
                                    <p className='description' data-aos="fade-up">
                                        Welcome to Wh3el City! A metverse housing real businesses to promote their products globally. Discover new market potentials and reach customers worldwide with Wh3el City! Leveling up your experience with ability to play games and integrate NFTs inside
                                    </p>
                                    <ul>
                                        <li data-aos="fade-up">Fully Customized advert game</li>
                                        <li data-aos="fade-up">Sales of digital goods</li>
                                        <li data-aos="fade-up">NFT ownership benefits</li>
                                        <li data-aos="fade-up">Real World and Social Benefit</li>
                                    </ul>
                                    {/* <a class="btn btn-google" href="#" id="btn-google" title="Google Play" data-aos="fade-up">Google Play</a> */}
                                    <div data-aos="fade-up">
                                        {
                                            (!account) ? <a style={{ width: "100px" }} className="btn btn-dark " href="/login" >Login</a> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="fade-up">
                                <img src={metaverseImg} alt="" className='metaverseImg'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>

            <div className="homeMatrix">
                <div className="homeMatrixOverlay">
                    <div className='m-3 text-center'>
                        <p className="emveep-nft-box mx-auto mb-3" data-aos="fade-up">// ROADMAP</p>
                        <div className="homeCenterService">
                            <p className="homeSectionBigText" data-aos="fade-up">
                            Proper Blockchain Startup
                            </p>
                            <p className="mt-4" data-aos="fade-up">
                            Our goal is to establish ourselves as the foremost blockchain startup, providing a trustworthy platform for businesses and consumers alike.
                            </p>
                        </div>
                        <div className="homePageContainer" style={{marginTop: "80px", marginBottom: "60px"}}>
                            {
                                innerWidth > 768 ?
                                    <>
                                        <div className="draw-line" data-aos="fade-up"></div>
                                        <div data-aos="fade-up" className='roadmap-img-container left'>
                                            <img src={roadmapImg1} className="img-fluid roadmap-img"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container right'>
                                            <img src={roadmapImg2} className="img-fluid roadmap-img"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container left'>
                                            <img src={roadmapImg3} className="img-fluid roadmap-img"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container right'>
                                            <img src={roadmapImg4} className="img-fluid roadmap-img"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container left'>
                                            <img src={roadmapImg5} className="img-fluid roadmap-img"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container right'>
                                            <img src={roadmapImg6} className="img-fluid roadmap-img"/>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <div data-aos="fade-up" className='roadmap-img-container center'>
                                            <img src={lineMobile} className="img-fluid roadmap-img-line"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container'>
                                            <img src={roadmapImg1Mobile} className="img-fluid roadmap-img-mobile"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container'>
                                            <img src={roadmapImg2Mobile} className="img-fluid roadmap-img-mobile"/>
                                        </div>
                                        <div data-aos="fade-up" className='roadmap-img-container'>
                                            <img src={roadmapImg3Mobile} className="img-fluid roadmap-img-mobile"/>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>

            <div className="homeOurProducts mt-5">
                <div className="homePageContainer text-center">
                    <p className="emveep-nft-box mx-auto mb-3" data-aos="fade-up">// Products</p>
                    <div className="homeCenterService mb-5">
                        <p className="homeSectionBigText" data-aos="fade-up">
                        Our Products
                        </p>
                    </div>
                    <div className="row row-flex m-3">
                        <div className="col-md-6">
                            <div className="card card-bg-purple card-bg-image-1" data-aos="fade-up">
                                <div className={innerWidth > 1080 ? 'card-container' : ""}>
                                    <p className='text-white' data-aos="fade-up">NFT Launchpad</p>
                                    <small data-aos="fade-up">A space where digital collectibles take off into the virtual world.</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-bg-purple card-bg-image-1" data-aos="fade-up">
                                <div className={innerWidth > 1080 ? 'card-container' : ""}>
                                    <p className='text-white' data-aos="fade-up">Tokenization Platform</p>
                                    <small data-aos="fade-up">Convert real-world assets into digital tokens for trading and investment.</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card card-bg-purple card-bg-image-2" data-aos="fade-up">
                                <div className={innerWidth > 1080 ? 'card-container' : ""}>
                                    <p className='text-white' data-aos="fade-up">Virtual Business (Metaverse Edutainment)</p>
                                    <small data-aos="fade-up">Explore and learn in virtual realms for both entertainment and education.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>

            <div className="homeOurVision">
                <div className="homePageContainer">
                    <div className="row">
                        {
                            innerWidth > 1080 ?
                                <div className="col-md-3" data-aos="fade-up">
                                    <img src={ourVisionImg} alt="" style={{width: '100%'}}/>
                                </div>
                            : null
                        }
                        <div className={innerWidth > 1080 ? 'col-md-9 my-auto px-5' : 'col-md-12 my-auto px-5'}>
                            <p className="emveep-nft-box mb-3" data-aos="fade-up">// Our Vision</p>
                            <div className="">
                                <p className="homeSectionBigText" data-aos="fade-up">
                                Our Vision
                                </p>
                                <p className="mt-4" data-aos="fade-up">
                                We want to build a thriving, legal web 3.0 ecosystem where business and entrepreneurs can enjoy economic benefits from NFT, blockchain, and tokenization.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>

            <div className='container-fluid'>
                <div className='join-us-sect mx-auto' style={{backgroundImage: `url('${JoinUsBG}')`}} data-aos='fade-up'>
                    <div className='join-us-text-1' data-aos='fade-up'>Find us on X & Discord</div>
                    <div className='join-us-text-2' data-aos='fade-up'> We'll always make you feel welcome!</div>
                    <div className='d-flex medsos-sect'>
                        <a href="https://discord.com/invite/DxhjMQZJ5n" className='geeky-medsos' data-aos='fade-up'>
                            <img
                                className='pointer mx-auto'
                                src={imgDiscord}
                                width={20}
                                height={20}
                                alt='ic-discord'
                                style={{filter: 'invert(1)'}}
                                // onClick={handleIncrement}
                            />
                        </a>
                        <a href="https://twitter.com/wh3el_io" className='geeky-medsos' data-aos='fade-up'>
                            <img
                                className='pointer mx-auto'
                                src={imgTwitter}
                                width={20}
                                height={20}
                                alt='ic-twitter'
                                style={{filter: 'invert(1)'}}
                                // onClick={handleIncrement}
                            />
                        </a>
                    </div>

                </div>
            </div>

            <div style={{marginBottom: "20px"}}>
                &nbsp;
            </div>
        </>
    );
}