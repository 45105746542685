
export default function Community() {
    return (
        <>
            <div className="topSpacer"></div>
            <div className="pageContainer">
                <h1>Community</h1>
                <div>Lorem ipsum dolor sit amet</div>
            </div>
        </>
    );
}