import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import NoAccount from '../components/no-account';
import { voucherService } from '../services';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Alert } from 'react-bootstrap';
import imgMoney from '../images/money.png';
import useWindowDimensions from '../components/useWindowDimensions ';

export default function PoinExchange() {

    const { height, width } = useWindowDimensions();
    const [panelHeight, setPanelHeight] = useState(210);
    const [panelHeightR, setPanelHeightR] = useState(150);
    const { t } = useTranslation();
    const context = useOutletContext();
    const [account, setAccount] = useState(context.account);

    const [curPage, setCurPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage, setPerPage] = useState(10000);
    const [dataItems, setDataItems] = useState(null);
    const [loadingAction, setLoadingAction] = useState(0);

    const [activeItem, setActiveItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [accountTotaPoin, setAccountTotaPoin] = useState(0);

    const getIndex = (page, size) => {
        voucherService.list(page, size).then(x => {
            setDataItems(x.result);
            setCurPage(x.meta.currentPage);
            setPerPage(x.meta.perPage);
            setPageCount(x.meta.pageCount);
        });
    }

    const handlePageClick = (p) => {
        const page = p.selected;
        setCurPage(page);
        getIndex(page, perPage);
    };

    const handleBoxVoucherClick = (item) => {
        setShowAlert(false);
        setAlertMessage("");
        const collection = document.getElementsByClassName("divVoucherBox");
        Array.prototype.forEach.call(collection, function (el) {
            el.classList.remove("selected");
        });

        setActiveItem(item);
        //console.log("voucher-" + item.id);
        let elem = document.getElementById("voucher-" + item.id);
        if (elem) {
            elem.classList.add("selected");
        }
    };

    const handleExchangeClick = (item, qty) => {
        confirmAlert({
            overlayClassName: "my-alert",
            title: '',
            //message: 'Are you sure to exchange poin?',
            message: `Apakah Anda yakin untuk menukar ${item.exchangePoin} Poin dengan ${item.name}?`,
            buttons: [
                {
                    label: 'Ya',
                    className: 'btn-confirm-yes',
                    onClick: () => {
                        setLoadingAction(item.id);
                        voucherService.exchange(item.id, qty).then((res) => {
                            console.log(res);
                            setLoadingAction(0);
                            getIndex(0, perPage);
                            setActiveItem(null);
                            setShowAlert(true);
                            setAlertMessage(res.message);
                            setAccountTotaPoin(res.data?.totalPoin);
                        }).catch((err) => {
                            setActiveItem(null);
                            setShowError(true);
                            setErrMessage(err);
                        });
                    }
                },
                {
                    label: 'Tidak',
                    className: 'btn-confirm-no',
                    onClick: () => {
                        // do nothing
                    }
                }
            ]
        });
    };

    useEffect(() => {
        setAccount(context.account);
        setAccountTotaPoin(context.account?.totalPoin);
    }, [context.account]);

    useEffect(() => {
        getIndex(curPage, perPage);
        setPanelHeight(height - 100);
        setPanelHeightR(height - 147);
    }, []);

    return (
        <>

            <div className="pageContainer">
                {
                    (account) ?
                        <>

                            <div className="divBreadcrum">
                                <span>Home</span>
                                <span style={{ margin: "0 10px" }}>/</span>
                                <span style={{ fontWeight: 700 }}>Point Exchange</span>
                            </div>

                            <div style={{ marginBottom: "20px" }}>
                                <span className="pageWidgetTitle">Total Point</span>
                                <span style={{
                                    fontSize: "24px",
                                    margin: "0 15px 0 20px",
                                    borderLeft: "1px solid #DDDDDD"
                                }}>&nbsp;</span>
                                <img style={{ marginTop: "-10px" }} src={imgMoney} alt="Money" />
                                <span style={{ padding: "10px", fontSize: "18px", fontWeight: 700 }} >{accountTotaPoin}</span>
                            </div>



                            {
                                (dataItems) ? <>

                                    <div className="globalBoxWebPanel">
                                        <div className="row">
                                            <div className="col scroll" style={{ height: `${panelHeight}px` }}>
                                                {
                                                    dataItems.map((item, i) => {
                                                        ++i;

                                                        return (
                                                            <div key={item.id} className="divVoucherBox" id={`voucher-${item.id}`} onClick={() => handleBoxVoucherClick(item)}>
                                                                <div><img className="img-fluid" src={item.image} alt="" /></div>
                                                                <div className="globalItemCode">{item.code}</div>
                                                            </div>
                                                        )
                                                    })
                                                }



                                                <div className="clearfix"></div>


                                            </div>

                                            <div className="col">
                                                {
                                                    (activeItem) ?
                                                        <>
                                                            <div className="boxVoucherInformation" style={{ height: `${panelHeightR}px` }}>
                                                                <div style={{ float: "left", fontWeight: "bold" }}>{activeItem.name}</div>
                                                                <div style={{ float: "right", fontWeight: "bold" }}>
                                                                    <span><img src={imgMoney} alt="Money" style={{ width: "15px" }} /> {activeItem.exchangePoin}</span>
                                                                </div>
                                                                <div className="clearfix"></div>

                                                                <div><span className="voucherLabel">{t('code')}:</span> {activeItem.code}</div>
                                                                <div><span className="voucherLabel">{t('merchant')}:</span> {activeItem.Merchant.name}</div>
                                                                <div className="voucherDescription">
                                                                    {activeItem.description}
                                                                </div>

                                                            </div>

                                                            <div className="mt-3 text-right">
                                                                <span className="btn btn-primary" onClick={() => handleExchangeClick(activeItem, 1)}>{t('exchange.btn')}</span>
                                                            </div>


                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                (showAlert) ? <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                                                                    <Alert.Heading></Alert.Heading>
                                                                    <p>{alertMessage}</p>
                                                                </Alert> : ""
                                                            }

                                                            {
                                                                (showError) ? <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                                                                    <Alert.Heading></Alert.Heading>
                                                                    <p>{errMessage}</p>
                                                                </Alert> : ""
                                                            }

                                                            <div>
                                                                <i>{t('click.item.description')}</i>
                                                            </div>

                                                        </>
                                                }

                                            </div>

                                        </div>
                                    </div>

                                </>
                                    : <>
                                        <div>No data found.</div>
                                    </>
                            }
                        </>
                        : <NoAccount />
                }

            </div>
        </>
    );
}