import { fetchWrapper } from '../helpers';

const apiUrl = process.env.REACT_APP_BASE_API;
const baseUrl = `${apiUrl}/api/public/xxx`;

export const accountNftService = {
    myNfts
};

async function myNfts(page, size, firstCreator = null) {
    let str = "";
    if (firstCreator != null) {
        str = ("&firstCreator=" + firstCreator);
    }
    return fetchWrapper.get(`${apiUrl}/api/public/account/my-nfts?page=${page}&size=${size}${str}`);
}
