import { useState, useEffect, useRef } from 'react';
import banner1 from '../images/banner1.png';
import tround from '../images/tround.png';
import iconDiscord from '../images/icon-discord-w.png';
import iconTelegram from '../images/icon-telegram-w.png';
import iconTwitter from '../images/icon-twitter-w.png';
import iconFacebook from '../images/icon-facebook-w.png';
import imgFrame9 from '../images/frame9.png';
import imgNft from '../images/nft.png';
import imgArrowRight from '../images/arrow-right.png';
import check from '../images/check.svg';

import { useParams } from "react-router-dom";

import '../nft-buildings-v3.css';

export default function NftBuilding() {
    const params = useParams();


    return (
        <>
            <div className="pageContainer">
                <div style={{ height: "26px" }}></div>
                <div className="pageWidgetTitle">Mint NFT {params.numId}</div>

                <div style={{ marginBottom: "15px" }}></div>

                <div className="row">
                    <div className="col-sm-4">
                        <div><img src={imgNft} alt="Techpolitan" className="img-fluid rounded" /></div>
                        <div>
                            <button className="btn btn-primary btn-block mt-3">Mint</button>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="pl-3">
                            <div className="divImgNft_title_big mb-3">Meta Estates 1A</div>
                            <div className="row">
                                <div className="col">
                                    <div>CREATOR</div>
                                    <div>
                                        <span style={{ fontSize: "18px", fontWeight: 700, marginRight: "10px" }}>@AdidasNFTs</span>
                                        <img src={check} alt="Techpolitan" style={{ width: "18px" }} />
                                    </div>
                                </div>
                                <div className="col">
                                    <div>TOTAL ITEMS</div>
                                    <div>
                                        <span style={{ fontSize: "18px", fontWeight: 700, marginRight: "10px" }}>1,000</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>PRICE</div>
                                    <div>
                                        <span style={{ fontSize: "18px", fontWeight: 700, marginRight: "10px" }}>10.5 ETH</span>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <div className="mb-3">DESCRIPTION</div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>

                            <div className="mt-5">
                                <div className="mb-3">PROPERTIES</div>
                                <div className="row">
                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="divNftProps">
                                            <div>Backgound</div>
                                            <div className="divNftPropsValue">Grade Grey</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <div className="mb-3">SHARE</div>
                                <div>
                                    <a href="https://google.com" style={{ marginRight: "15px" }}>
                                        <img src={iconDiscord} alt="Discord" />
                                    </a>
                                    <a href="https://google.com" style={{ marginRight: "15px" }}>
                                        <img src={iconTelegram} alt="Telegram" />
                                    </a>
                                    <a href="https://google.com" style={{ marginRight: "10px" }}>
                                        <img src={iconTwitter} alt="Twitter" />
                                    </a>
                                    <a href="https://google.com">
                                        <img src={iconFacebook} alt="Facebook" />
                                    </a>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

                <div style={{borderTop: "1px solid #DDDDDD", marginTop: "50px", marginBottom: "20px"}}></div>

                <div className="mt-4 mb-4" style={{textAlign: "right"}}>
                    <a href="/nft-buildings">
                        <span className="nft-see-all-collection" style={{paddingRight: "10px"}}>SEE ALL Collection</span>
                    </a>
                </div>

                <div className="row">
                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title">Meta Estates 1A</div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn bg-dark" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title">Meta Estates 1A</div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn bg-dark" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divImgNft">
                        <div className="divImgNft_header">
                            <img src={imgNft} alt="Techpolitan" className="img-fluid" />
                        </div>
                        <div className="divImgNft_content">
                            <div className="divImgNft_title">Meta Estates 1A</div>
                            <div>#1</div>
                            <div className="row mt-3">
                                <div className="col">
                                    <span className="divImgNft_title">31.50 ETH</span>
                                </div>
                                <div className="col text-right">
                                    <button className="btn bg-dark" style={{ width: "150px" }}>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div className="row">
                    <div className="home_divArrowRight">
                        <img src={imgArrowRight} alt="Techpolitan" className="img-fluid" />
                    </div>
                </div>


            </div>
        </>
    );
}